<template>
<div id="">
  <pub-yun-ext-stock  v-if='hasRight("PubYunExtStock")' :searchModel="pubYunExtStock.searchModel" :height="height">
    </pub-yun-ext-stock>
  </div>
</template>

<script>
import pubYunExtStock from '@c_modules/StkExt/PubYunExtStock_v1'

export default {
  name: 'VPubYunExtStock',
  components: {
    pubYunExtStock
  },
  data () {
    return {
      pubYunExtStock: {
        searchModel: { },
        pickerOptions0: {}
      },
      pageData: [],
      height: '97%'
    }
  },
  methods: {
    async getPageData () {
      let pageUrl = this.$store.state.accountInfo.pageAuthorUrl
      return await this.$post(pageUrl, {PageCode: 'VPubYunExtStock'})
    },
    hasRight (code) {
      return _.some(this.pageData, page => page.Code === code)
    }
  },
  async created () {
    this.getPageData().then((data) => {
      this.pageData = data
      this.$nextTick(() => {
        initData(this)
      })
    }).catch((e) => {
      console.log(e)
    })

    const initData = (component) => {
      if (component.$options.config && component.$options.config.configURL) {
        const moduleName = component.$options.config.configURL.split('/')[0]
        const config = _.find(this.pageData, page => page.Code === moduleName)
        if (config && component.onInit) {
          component.onInit(config.UICPageGridConfig)
        } else {
          component.onInit()
        }
      }
      _.each(component.$children, child => {
        initData(child)
      })
    }
  }
}

</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>
