<template>
  <znl-dialog
    :visible="visible"
    title="批量导入"
    height="350px"
    width="850px"
    :close-on-click-modal="false"
    :is-footer-show="false"
    v-loading="isLoading"
    element-loading-text="请稍后..."
    @close="()=>{$emit('close')}"
  >
    <!-- 导入数据组件 -->
    <pasteDataAcquisition
      ref="pasteDataAcquisition"
      :columns="columns"
      :show-columns="showcolumnsheader"
      class="bom_pasteDataAcquisition"
      :rows="10"
      :max-count="2000"
      :auto-mapping-column="false"
      :show-loading="true"
      @set-columns-list="val=>{showcolumnsheader.push(val)}"
      @add-excel-data="addExcelData"
      @set-column-order="(colsOrder) => {headerColumnsOrder = colsOrder}"
    ></pasteDataAcquisition>
  </znl-dialog>
</template>

<script>
import pasteDataAcquisition from '@c_common/znlGrid/commonComponents/pasteDataAcquisition'

export default {
  name: 'paste-data-dialog',
  components: {
    pasteDataAcquisition
  },
  data() {
    return {
      columns: [
        {
          "name": "Model",
          "dataType": 1,
          "binding": "Model",
          "header": "型号",
          "visible": true,
          "sort": 1,
          "isReadOnly": false
        },
        {
          "name": "Brand",
          "dataType": 1,
          "binding": "Brand",
          "header": "品牌",
          "visible": true,
          "sort": 2,
          "isReadOnly": false
        },
        {
          "name": "Packaging",
          "dataType": 1,
          "binding": "Packaging",
          "header": "封装",
          "visible": true,
          "sort": 3,
          "isReadOnly": false
        },
        {
          "name": "MakeYear",
          "dataType": 1,
          "binding": "MakeYear",
          "header": "年份",
          "visible": true,
          "sort": 4,
          "isReadOnly": false
        },
        {
          "name": "Qty",
          "dataType": 2,
          "binding": "Qty",
          "header": "数量",
          "visible": true,
          "sort": 5,
          "isReadOnly": false
        },
        {
          "name": "DeliveryDate",
          "dataType": 1,
          "binding": "DeliveryDate",
          "header": "货期",
          "visible": true,
          "sort": 6,
          "isReadOnly": false
        },
        {
          "name": "Quality",
          "dataType": 1,
          "binding": "Quality",
          "header": "品质",
          "visible": true,
          "sort": 7,
          "isReadOnly": false
        },
        {
          "name": "Price",
          "dataType": 1,
          "binding": "Price",
          "header": "价格",
          "visible": true,
          "sort": 8,
          "isReadOnly": false
        },
        {
          "name": "WareHouse",
          "dataType": 1,
          "binding": "WareHouse",
          "header": "仓库",
          "visible": true,
          "sort": 9,
          "isReadOnly": false
        },
        {
          "name": "Explain",
          "dataType": 1,
          "binding": "Explain",
          "header": "说明",
          "visible": true,
          "sort": 10,
          "isReadOnly": false
        }
      ],
      showcolumnsheader: [],
      isLoading: false
    }
  },

  methods: {
    addExcelData(rows) {
      this.isLoading = true
      this.$emit('add-excel-data', rows)
      this.isLoading = false
    }
  },

  props: {
    visible: Boolean,
  }
}
</script>
