<template>
  <znl-dialog :visible="visible&&dialogShow"
              :title="showTitle"
              width="800px"
              height="640px"
              @close="()=>{$emit('close')}">
    <div slot="znl-dialog">
      <div v-show="showRule">
        <ul class="Bom_myupload_fromalert_row">
          <li><span>列名</span><span>字符要求</span><span>格式要求</span></li>
          <li><span>型号</span><span>40个字符</span><span>型号不能为空且由英文字母、数字、点符号 “ . ” 、下划线 “ _ ” 、中杠 “ - ” 、加号 “ + ” 、井号 “ # ” 、逗号 “ , ” 、全角逗号 “ ，” 、全角中点号 “ ? ” 、@号 “ @ ” 、反斜杠 “ / ” 、百分号 “ % ” 、全角百分号 “ % ” 、$号 “ $ ” 、分号 “ : ” 、小括号 “ () ” 、等于号 “ = ” 、*号 “ * ” 、&amp;号 “ &amp; ” 组成</span></li>
          <li><span>品牌</span><span>40个字符</span><span>中文、英文字母、数字、横杠 “ - ” 、反斜杠 “ / ” 、&amp;号 “ &amp; ” 系统会过滤的内容：“ 现货 ” 、 “ 假一赔十 ” 、“ 厂家直销 ” ，不允许全角</span></li>
          <li><span>批号</span><span>20个字符</span><span>数字、加号 “ + ” 、反斜杠 “ / ” 、英文、括号 “ () ” ，不允许全角</span></li>
          <li><span>封装</span><span>20个字符</span><span>英文、数字、横杠 “ - ” 、括号 “ () ” 、点号 “ . ” ，不允许全角</span></li>
          <li><span>数量</span><span>数字</span><span>不能为空且大于0</span></li>
          <li><span>交易说明</span><span>40个字符</span><span>中文、数字、英文、点符号 “ . ” 、 中杠 “ - ” 、 斜杠 “ / ” 、 反斜杠 “ \ ” ，不允许全角</span></li>
          <li><span>仓库位置</span><span>数字</span><span>填写认证仓库所对应的编号，例如：仓库 1 的位置是 中国大陆 - 广东 - 深圳，在对应的库存的仓库位置列填 1 即可</span></li>
        </ul>
      </div>

      <div v-show="showUpload">
        <ul class="Bom_myupload_fromalert_row my-upload">
          <li>
            <span>覆盖模式</span>
            <span>
              上传成功后，指定的推广类型的原有库存，将会被全部删除。然后导入本次上传的推广库存。
              <div class="eg">
                例如：您原有的原装推广库存为LM158, LM258, LM358 共三个。本次上传的原装推广库存为LM158A, LM258A, LM358A 共三个。导入成功后，系统会删除原有的原装推广库存LM158, LM258, LM358，新增本次上传的原装推广库存为LM158A, LM258A, LM358A。
              </div>
            </span>
          </li>
          <li>
            <span>替换模式</span>
            <span>
              上传成功后，指定的推广类型的原有库存，不会被删除。
              如果本次上传的库存跟原有的库存有重叠时，重叠的部分将会使用本次上传的推广库存，替换掉原有的推广库存。
              <!-- 上传成功后，原有指定推广类型的库存已经存在更新，不存在则添加。 -->
              <br>
              <lable style="color:red;">(判断是否重叠的条件：型号、品牌、封装相同)。</lable>
              <div class="eg">
                例如：您原有的原装推广库存为<label class="font-bold">LM158</label>, LM258, LM358 共三个。
                本次上传的原装推广库存为<label class="font-bold">LM158</label>, LM258A, LM358A 共三个。
                其中<label class="font-bold">LM158</label>原有的跟上传的品牌，封装完全相同,
                上传成功后，系统会删除原有的原装推广库存<label class="font-bold">LM158</label>, 保留LM258, LM358，
                新增本次上传的原装推广库存<label class="font-bold">LM158</label>, LM258A, LM358A。
              </div>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <el-row slot="footer" class="footer-text">
      <div v-show="showRule">
        <p class="rule-warn">注意：当型号、品牌、封装位置这3列内容完全相同时，此类库存默认为相同库存</p>
      </div>
      <el-button type="default"
                :disabled="loading"
                @click="onCancel"
                class="znl-btn-auxiliary">关闭</el-button>
    </el-row>
  </znl-dialog>
</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'

export default {
  name: 'PubStkStockImportRule',
  mixins: [propSyncMixin],
  components: {
  },
  data () {
    return {
      dialogShow: true,
      showWidth: '800px',
      showHeight: '510px',
      showRule: true,
      showUpload: false,
      showTitle: '推广库存信息资料的检测与过滤规则'
    }
  },
  props: {
    loading: Boolean,
    visible: Boolean,
    showType: Number,
    width: String,
    height: String
  },
  watch: {
    visible (val) {
      if (val === true) {
        this.dialogShow = true
      }
    },
    // showType: {
    //   handler (val) {

    //   },
    //   immediate: true
    // },
    showType (val) {
      if (val === 1) {
        this.showWidth = '800px'
        this.showHeight = '510px'
        this.showRule = true
        this.showUpload = false
        this.showTitle = '推广库存信息资料的检测与过滤规则'
      } else {
        this.showWidth = '480px'
        this.showHeight = '380px'
        this.showRule = false
        this.showUpload = true
        this.showTitle = '上传模式说明'
      }
      console.log(this.showTitle)
    }
  },
  methods: {
    onInit: async function () {
    },
    onCancel () {
      this.dialogShow = false
    },
    isNull (val) {
      if (val === null || val === undefined || val === '') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.upload {margin-top: 20px;}
.mt20{margin-top: 20px;}

.Bom_myupload_fromalert_row {
  display: inline-block;
  vertical-align: top;
}
.Bom_myupload_fromalert_row li span {
  display: table-cell;
  color: #333;
  text-align: left;
  padding-left: 30px;
  font-size: 12px;
  overflow: hidden;
}
.Bom_myupload_fromalert_row li {
  border-bottom: 1px solid #DFE3E9;
}
.bom_Stock_fromalert_row li span:nth-child(1) {
  width: 130px;
  height: 40px;
  line-height: 40px;
}
.Bom_myupload_fromalert_row li span:first-child {
  width: 130px;
  height: 40px;
  line-height: 40px;
}
.Bom_myupload_fromalert_row li span:nth-child(2) {
  width: 130px;
  height: 40px;
  line-height: 40px;
}
.Bom_myupload_fromalert_row li span:first-child + span {
  width: 130px;
  height: 40px;
  line-height: 40px;
}
.Bom_myupload_fromalert_row li span:nth-child(3) {
  width: 480px;
  padding-bottom: 10px;
  padding-right: 30px;
}
.Bom_myupload_fromalert_row li span:first-child + span + span {
  width: 480px;

  padding-right: 30px;
}
.Bom_myupload_fromalert_row li:nth-child(1) span {
  font-size: 14px;
  font-weight: bold;
  color: #696969;
}
.Bom_myupload_fromalert_row li:first-child span {
  font-size: 14px;
  font-weight: bold;
  color: #696969;
}
.Bom_myupload_fromalert_row li:nth-child(8) span {
  border: none;
}
.Bom_myupload_fromalert_row li:first-child + li + li + li + li + li + li + li {
  border: none;
}
.my-upload li{
  width: 100%;
}
.my-upload li span{
  color: #333 !important;
  font-size: 12px !important;
  line-height: 30px !important;
}
.my-upload li span:nth-child(1){
  width: 62px !important;
}
.my-upload li span:nth-child(2){
  width: auto !important;
}
.my-upload li span{
  font-weight: normal !important
}
.my-upload .eg{
  line-height: 24px;
  color: #949191;
}
.my-upload .font-bold{
  font-weight: bold;
  color: #ad6060;
}
.rule-warn{
  color: #EE7700;
  text-align: left;
  float: left;
}
</style>
