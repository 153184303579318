var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-wrapper stockpricing"},[_c('trading',{attrs:{"token":_vm.token,"permissions":{
      ReceivedInquiry: true,
      SalesOrder: true,
      SalesChannels: true,
      ChannelAnalysis: true,
      InventoryPricing: true,
      PricingStrategy: true,
      OutgoingInquiry: true,
      PurchaseOrder: true
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }