<template>
  <znl-pub-account-set >
  </znl-pub-account-set>
</template>

<script>
import ZnlPubAccountSet from '@c_modules/StkExt/PubAccountSet'

export default {
  name: 'VPubAccountSet',
  components: {
    ZnlPubAccountSet
  },
  data () {
    return {
      stkStkinadd: { }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
</style>
