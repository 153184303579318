import { render, staticRenderFns } from "./PubStkStockImportFailDetail.vue?vue&type=template&id=768245d7&"
import script from "./PubStkStockImportFailDetail.vue?vue&type=script&lang=js&"
export * from "./PubStkStockImportFailDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports