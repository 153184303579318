
<template>
  <!-- 云价格推广 -->
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="action"
      checkboxBindingKey="YEID"
      :showCheck="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      @select-change="selectChange"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('PubYunExtStock')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <znl-input
          form-type="input"
          placeholder="请输入型号"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="110px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="PubYunExtStock.Model"
          :border="true"
          type="text"
        ></znl-input>

        <znl-input
          form-type="input"
          placeholder="请输入品牌"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="70px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="PubYunExtStock.Brand"
          :border="true"
          type="text"
        ></znl-input>

        <znl-input
          form-type="input"
          placeholder="请输入货期"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="70px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="PubYunExtStock.DeliveryDate"
          :border="true"
          type="text"
        ></znl-input>

        <znl-input
          form-type="select"
          :select-options="selectOptionsSStatus"
          placeholder="展示状态"
          :clearable="true"
          width="70px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="PubYunExtStock.SStatus"
          :border="true"
          type="text"
        ></znl-input>

        <znl-input
          form-type="datepicker"
          placeholder="上传时间起"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="105px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="PubYunExtStock.PDateStart"
          :border="true"
          type="text"
        ></znl-input>
        <znl-input
          form-type="datepicker"
          placeholder="上传时间止"
          :clearable="true"
          @keyup.enter.native="onSearch()"
          width="105px"
          size="mini"
          layout="left"
          inp-perc="100%"
          v-model="PubYunExtStock.PDateEnd"
          :border="true"
          type="text"
        ></znl-input>

        <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
          <znl-button-menu @click="onSearch()">
            <i class="iconfont icon-search_ic"></i>
            <span>搜索</span>
          </znl-button-menu>
          <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
            <i class="iconfont icon-arrow-drop-down_ic"></i>
          </znl-button-menu>
        </znl-button>
        <!-- 批量展示 -->
        <znl-button style-type="mac" :height="22" @click="openBatchShow(true)">
          <i class="iconfont icon-show_btn_ic"></i>
          <span>批量展示</span>
        </znl-button>

        <!-- 批量取消 -->
        <znl-button style-type="mac" :height="22" @click="openBatchShow(false)">
          <i class="iconfont icon-revoke_btn_ic"></i>
          <span>批量取消</span>
        </znl-button>

        <!-- 批量删除 -->
        <znl-button style-type="mac" :height="22" @click="batchDeleteClick()">
          <i class="iconfont icon-delete_btn_ic"></i>
          <span>批量删除</span>
        </znl-button>

        <!-- 批量导入 -->
        <znl-button style-type="mac" :height="22" @click="batchImport()">
          <i class="iconfont icon-leading-in-wh_btn_ic"></i>
          <span>批量导入</span>
        </znl-button>
      </div>

      <div slot="footer" class="v-table-footer-total">
        <el-row>
          <el-col :span="24">
            <span>
              {{cloudPriceInfo.Name}} :
              <span
                class="stsPurchaseIn-amount"
              >{{cloudPriceInfo.ViewCnt}} / {{cloudPriceInfo.BuyCnt}} 条</span>&nbsp;&nbsp;
            </span>
          </el-col>
        </el-row>
      </div>
    </znl-table>
    <v-pub-yun-ext-stock-add
      ref="PubYunExtStockAdd"
      title="批量导入"
      v-if="StkStockAddVisible"
      :visible="StkStockAddVisible"
      :searchModel="importModel"
      @close="()=>{StkStockAddVisible=false}"
      @confirm="(val)=>{val&&(StkStockAddVisible=false,this.onSearch()),this.$refs.table.cleanAllCheck()}"
    ></v-pub-yun-ext-stock-add>

    <!-- 批量展示、取消 -->
    <pub-yun-ext-stock-batch-show
      :visible="batchSetStatusVisible"
      :checked-rows="selectedRows"
      :search-condition="batchSetSearchCondition"
      :operate-type="batchOpereateType"
      @close="onSetStatusClose"
    ></pub-yun-ext-stock-batch-show>

    <no-service-warning v-if="!hasService"></no-service-warning>
  </div>
</template>

<script>
import vPubYunExtStockAdd from "@c_modules/StkExt/PubYunExtStockAdd";
import NoServiceWarning from "@c_modules/StkExt/NoServiceWarning";
import { erpTableSetHandler } from "@scripts/methods/common";
import PubYunExtStockBatchShow from "@c_modules/StkExt/PubYunExtStockBatchShow";
import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";

const CONFIG = {
  PK: "YEID",
  configURL: "PubYunExtStock/GetConfig",
  searchURL: "PubYunExtStock/Search",
  clearURL: "PubYunExtStock/ClearYunExtStock",
  editStockUrl: "PubYunExtStock/EditYunExtStock",
  hasServiceUrl: "PubStkStock/HasService",
  SumSearchListUrl: "StkExtList/GetExtensionCnt"
};

export default {
  name: "PubYunExtStock",
  mixins: [erpTableSetHandler, getCommonDataMixin],
  config: CONFIG,
  components: {
    elOption: Option,
    vPubYunExtStockAdd,
    NoServiceWarning,
    PubYunExtStockBatchShow
  },
  data() {
    return {
      isShowFiedsSearch: false,
      pickerOptions0: {},
      columns: [],
      itemSource: [],
      PubYunExtStock: {
        Model: "",
        Brand: "",
        DeliveryDate: "",
        SStatus: null,
        PDateStart: null,
        PDateEnd: null
      },
      cloudPriceInfo: {
        ExtensionType: 3,
        Name: "云价格推广",
        BuyCnt: 0,
        ViewCnt: 0
      },
      fieldsFilter: {},
      AllowDisplay: false,
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      loading: false,
      StkStockAddVisible: false,
      batchSetStatusVisible: false, // 批量展示、取消
      batchSetSearchCondition: {},
      batchOpereateType: "", //批量操作类型： status: 批量展示取消， delete: 批量删除
      changevs: false,
      SStatusOptions: [
        { key: "未展出", value: false },
        { key: "已展出", value: true }
      ],
      resourceList: [], // 权限列表
      hasService: false,
      importModel: [],
      selectedRows: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  async created() {
    let exists = await this.$post(CONFIG.hasServiceUrl, { ExtensionType: "3" });
    if (exists === 1) {
      this.hasService = true;
    }
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("pubYunExtStock", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;

      let colIndex = _.findIndex(columns, item => item.BindField === "SStatus");
      _.extend(columns[colIndex], {
        type: "select",
        width: 70,
        isFrozen: false,
        options: this.SStatusOptions
      });
      // colIndex = _.findIndex(columns, item => item.BindField === 'Model')
      // if (colIndex > -1) {
      //   _.extend(columns[colIndex], {
      //     isFrozen: true,
      //   })
      // }
      this.columns = columns;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.$refs.table.init();
      if (this.initData && this.hasService) {
        this.onBindData();
      }
      this.$emit("page-loading", false);
    },
    onBindData(pageIndex = 1) {
      if (!this.hasService) {
        return this.$message({
          message: "您尚未开通库存推广服务，请联系正能量客服人员",
          type: "error"
        });
      }
      this.pageIndex = pageIndex;
      //this.$refs.table.cleanSort()
      let data = this.getSearchParam();

      this.loading = true;
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList || [];
        this.totalCount = data.TotalCount;
        this.$emit("page-loading", false);
        this.getTotal();
        this.loading = false;
        this.onCleaSelectCount();
      });
    },
    //获取查询条件
    getSearchParam() {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: this.pageIndex,
          PageSize: this.pageSize
        },
        {
          Model: this.PubYunExtStock.Model,
          AllowDisplay: this.AllowDisplay,
          Brand: this.PubYunExtStock.Brand,
          DeliveryDate: this.PubYunExtStock.DeliveryDate,
          SStatus: this.PubYunExtStock.SStatus,
          PDateStart: this.PubYunExtStock.PDateStart,
          PDateEnd: this.PubYunExtStock.PDateEnd
        }
      );

      return data;
    },

    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
      }
      return this.onBindData(1);
    },
    batchImport() {
      if (!this.hasService) {
        return this.$message({
          message: "你尚未开通库存推广服务，请联系正能量客服人员",
          type: "error"
        });
      }
      this.StkStockAddVisible = true; // 显示页面
      this.importModel = [];
      this.changevs = !this.changevs;
    },

    // 打开批量展示、取消对话框
    openBatchShow(isShow) {
      if (!this.hasService) {
        return this.$message({
          message: "你尚未开通库存推广服务，请联系正能量客服人员",
          type: "error"
        });
      }
      this.batchOpereateType = "status";
      this.batchSetSearchCondition = this.getSearchParam();
      this.batchSetSearchCondition.NewSStatus = isShow;
      this.batchSetStatusVisible = true;
    },
    // 批量展示、取消, 批量删除窗口关闭时
    onSetStatusClose(isRefresh = false) {
      if (isRefresh) {
        this.onBindData(1);
      }
      this.batchSetStatusVisible = false;
    },

    // 批量删除
    batchDeleteClick() {
      if (!this.hasService) {
        return this.$message({
          message: "你尚未开通库存推广服务，请联系正能量客服人员",
          type: "error"
        });
      }

      this.batchOpereateType = "delete";
      this.batchSetSearchCondition = this.getSearchParam();
      this.batchSetStatusVisible = true;
    },

    onSaveSetting(columns, pageSize) {
      localStorage.removeItem("pubYunExtStock");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler(columns, pageSize, CONFIG.default)
      );
    },
    onResetSetting() {
      localStorage.removeItem("pubYunExtStock");
      return this.$post(CONFIG.resetConfigURL);
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem("pubYunExtStock");
      return this.$post(
        CONFIG.saveConfigURL,
        this.flexGridSettingHandler([column], this.pageSize, CONFIG.default)
      );
    },

    hasRes(code) {
      if (code.length === 0) {
        return false;
      }
      return _.includes(this.resourceList, code);
    },
    batchDelete() {
      var _vue = this;
      var yeids = [];
      var rows = this.selectedRows;
      if (!_.isUndefined(rows[0])) {
        var checkSStatus = [];
        yeids = rows.map(function(v) {
          return v.YEID;
        });
        checkSStatus = rows.map(function(v) {
          return v.SStatus;
        });
        this.PubYunExtStock.YEIDs = yeids;
        if (_.indexOf(checkSStatus, 1) >= 0) {
          this.$message({ message: "已展出的库存不能直接删除", type: "error" });
          return false;
        }
        var jsonData = _.extend(
          {},
          {
            FieldWhereString:
              Object.keys(this.PubYunExtStock).length > 0
                ? JSON.stringify(
                    _.extend(this.PubYunExtStock, { YEIDs: yeids })
                  )
                : JSON.stringify({ YEIDs: yeids }),
            YEIDs: yeids
          },
          this.searchModel.data
        );

        this.$post(CONFIG.clearURL, jsonData, data => {
          if (data > 0) {
            _vue.onSearch();
            this.$message({ message: "删除成功", type: "success" });
          } else {
            this.$message({ message: "删除失败", type: "error" });
          }
        }).then(() => {
          this.onBindData(this.pageIndex);
        });
      } else {
        return this.$message({
          message: "请选择一条数据进行删除",
          type: "error"
        });
      }
    },
    getTotal() {
      if (this.pageIndex === 1) {
        let vm = this;
        this.$post(
          CONFIG.SumSearchListUrl,
          { ExtensionType: vm.cloudPriceInfo.ExtensionType },
          data => {
            let cloud = _.find(
              data,
              m => m.ExtensionType === vm.cloudPriceInfo.ExtensionType
            );
            cloud && _.extend(vm.cloudPriceInfo, cloud);
          }
        );
      }
    },
    // 勾选选择框事件
    selectChange(rows) {
      this.selectedRows = rows;
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = [];
      this.$refs.table.cleanAllCheck();
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("PubYunExtStock", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("PubYunExtStock", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("PubYunExtStock", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("PubYunExtStock");
    }
  }
};
</script>
<style lang="scss">
.text {
  p {
    line-height: 10px;
  }
}
</style>
