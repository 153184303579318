<template>
  <!-- 库存定价 -->
  <div class="module-wrapper stockpricing">
    <!-- 表格组件 -->
    <trading
      :token="token"
      :permissions="{
        ReceivedInquiry: true,
        SalesOrder: true,
        SalesChannels: true,
        ChannelAnalysis: true,
        InventoryPricing: true,
        PricingStrategy: true,
        OutgoingInquiry: true,
        PurchaseOrder: true
      }"
    ></trading>
  </div>
</template>

<script>

import { getCookie } from '~assets/scripts/utils/dom'
import { ERPCOOKIENAME } from '@scripts/config'
export default {
  name: 'PubSotckPricing',

  data() {
    return {
      token: ''
    }
  },
  created() {
    const token = getCookie(ERPCOOKIENAME, this.$store)
    this.token = token.substr(6, token.length)
    console.log('get token.', this.token)
  }
}

</script>
<style lang="scss">
.stockpricing {
  .el-range-separator {
    width: 25px;
    font-size: 12px;
  }
}
</style>
