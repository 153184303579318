const Columns = [{
    binding: 'Grade',
    name: 'Grade',
    header: '类别',
    visible: true,
    dataType: 1,
    isReadOnly: false,
    isFieldSearch: true,
    isSystem: false,
    renderCell(h, cellObj) {
      let Grade = 'A';
      switch (cellObj.row.Grade) {
        case 1:
          Grade = 'A';
          break;
        case 2:
          Grade = 'B';
          break;
        case 3:
          Grade = 'C';
          break;

        default:
          break;
      }
      return h('span', Grade);
    }
  },
  {
    binding: 'GradeDiscount',
    name: 'GradeDiscount',
    header: '类别/折扣',
    visible: true,
    dataType: 1,
    isReadOnly: false,
    isFieldSearch: false,
    isSystem: false,
    renderCell(h, cellObj) {
      return h('div', [
        h('znl-input', {
          class: 'cell-input',
          props: {
            value: cellObj.row.Discount,
            border: true,
            height: 30,
            width: 40
          },
          // attrs: { value: cellObj.row.Discount },
          on: {
            input(value) {
              cellObj.row.Discount = value;
            }
          }
        }),
        h('span', ' 折')
      ]);
    }
  },
  {
    binding: 'CustomerCount',
    name: 'CustomerCount',
    header: '数量',
    visible: true,
    dataType: 1,
    isReadOnly: false,
    isFieldSearch: false,
    isSystem: false,
    renderCell(h, cellObj) {
      return h('span', [h('span', cellObj.row.CustomerCount), h('span', ' 家')]);
    }
  }
];

const Characters = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
  10: '十',
  11: '十一',
  12: '十二',
  13: '十三',
  14: '十四',
  15: '十五',
  16: '十六',
  17: '十七',
  18: '十八',
  19: '十九',
  20: '二十'
};

function NumToCharacters(num) {
  const Chat = Characters[num] || num;
  return Chat;
}

export {
  Columns,
  NumToCharacters
};
