
<template>
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="base"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      @on-failListJson-click="onFailListJsonClick"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('PubStkStockImport')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar">
        <!-- 上传推广库存 -->
        <znl-button style-type="main" :height="22" v-if="hasService" @click="batchImport2()">
          <i class="iconfont icon-leading-in-wh_btn_ic"></i>
          <span>上传库存</span>
        </znl-button>
        <znl-button style-type="mac" :height="22" :width="50" @click="onSearch()">
          <i class="iconfont icon-refresh_btn_ic"></i>
          <span>刷新</span>
        </znl-button>
      </div>
    </znl-table>
    <pub-stk-stock-import-fail-detail
      ref="PubStkStockImportFailDetail"
      :visible="detailVisible"
      :refresh="refreshModel"
      :failListJson="failListJson"
      @close="()=>{detailVisible=false}"
      :initData="true"
    ></pub-stk-stock-import-fail-detail>
    <v-pub-stock-import
      ref="vPubStockImport"
      :visible="pubStockImportVisible"
      @upload-success="(val)=>{pubStockImportVisible=!val}"
      @close="()=>{pubStockImportVisible=false}"
      @confirm="(v)=>{pubStockImportVisible=false,v&&this.onSearch()}"
    ></v-pub-stock-import>
  </div>
</template>
<script>
import PubStkStockImportFailDetail from '@c_modules/StkExt/PubStkStockImportFailDetail'
import vPubStockImport from '@c_modules/StkExt/PubStkStockImport'
import { erpTableSetHandler } from '@scripts/methods/common'

const CONFIG = {
  PK: 'MTGuid',
  configURL: 'PubStkStockImport/GetConfig',
  searchURL: 'PubStkStockImport/SearchHistory',
  hasServiceUrl: 'PubStkStock/HasService'
}
export default {
  name: 'ImportHistory',
  mixins: [erpTableSetHandler],
  config: CONFIG,
  components: {
    PubStkStockImportFailDetail,
    vPubStockImport
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      fieldsFilter: {},
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      loading: false,
      // 绑定推广类型
      STypeOptions: [
        { key: '正品企业', value: 4 },
        { key: '正品物料', value: 8 },
        { key: '保证有料', value: 6 },
        { key: '正品期货', value: 5 },
        { key: '优势推广', value: 9 }
      ],
      ResultOptions: [
        { key: '未处理', value: 0 },
        { key: '成功', value: 1 },
        { key: '失败', value: 2 }
      ],
      hasService: false,
      pubStockImportVisible: false,
      detailVisible: false,
      refreshModel: false,
      failListJson: '',
      officalPriceRefresh: true,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  async created() {
    let exists = await this.$post(CONFIG.hasServiceUrl, {})
    if (exists === 1) {
      this.hasService = true
    }
  },
  async mounted() {
    await this.onInit()
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('pubStkStockImprotHistory', CONFIG.configURL)
      }
      let columns = config.ColumnConfigs

      // 推广类型
      _.extend(columns[_.findIndex(columns, item => item.BindField === 'SType')], {
        type: 'select',
        width: 90,
        options: this.STypeOptions
      })

      // 上传状态
      _.extend(columns[_.findIndex(columns, item => item.BindField === 'Result')], {
        type: 'select',
        width: 70,
        options: this.ResultOptions
      })

      _.extend(columns[_.findIndex(columns, item => item.BindField === 'FailListJson')], {
        event: 'on-failListJson-click',
        className: 'v-cell-link'
      })
      this.columns = columns
      this.defaultConfig = config
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize
      this.$refs.table.init()
      if (this.initData) {
        this.onSearch();
      }
      this.$emit('page-loading', false)
    },
    async onBindData(pageIndex = 1) {
      //this.$refs.table.cleanSort()
      this.loading = true
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        })
      return await this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.$emit('page-loading', false)
        this.loading = false
      })
    },
    onSearch(params) {
      if (this.hasValue(params)) {
        this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      }
      return this.onBindData(1)
    },
    onSaveSetting(columns, pageSize) {
      localStorage.removeItem('pubStkStockImprotHistory')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler(columns, pageSize, CONFIG.default))
    },
    onResetSetting() {
      localStorage.removeItem('pubStkStockImprotHistory')
      return this.$post(CONFIG.resetConfigURL)
    },
    // 右键点击表头设置列
    onSaveConfig(column) {
      localStorage.removeItem('pubStkStockImprotHistory')
      return this.$post(CONFIG.saveConfigURL, this.flexGridSettingHandler([column], this.pageSize, CONFIG.default))
    },
    batchImport2() {
      // 批量导入推广库存
      this.pubStockImportVisible = true
      this.officalPriceRefresh = !this.officalPriceRefresh
    },
    onFailListJsonClick(e) {
      if (e.field !== 'FailListJson') {
        return
      }
      if (this.hasValue(e.row.FailListJson)) {
        this.failListJson = e.row.FailListJson
        this.detailVisible = true
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("PubStkStockImport", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("PubStkStockImport", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("PubStkStockImport", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("PubStkStockImport")
    }
  }
}
</script>
<style lang="scss">
</style>

