<template>
  <znl-dialog
    :visible="visible"
    width="900px"
    height="750px"
    class="grid-pubaccountset-box dialog-grid-footer-box"
    title="设置账号"
    :footer-height="0"
    @close="()=>{$emit('close')}"
  >
    <znl-gridmodule
      ref="flexGrid"
      height="85%"
      gridtype="base"
      :has-znl-actions="false"
      :is-btn="false"
      :columns="columns"
      :item-source="itemSource"
      :hasDefaultBeforeNewRow="true"
      :column-colors="columnColors"
      :row-colors="rowColors"
      :is-fields-search="true"
      :on-search="onSearch"
      :is-alter-ver="true"
      :search-fields="searchModel"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :on-page-changed="onBindData"
      :on-init="onInit"
      :loading="loading"
      :element-loading-text="loadingText"
      :qtyAbs="true"
      :is-init="true"
      :qtyClear="true"
      from="accountset"
      :users="$store.state.users"
      :on-deleted-checked-rows="()=>{CalcPay(false)}"
      @selection-changed="d=>{$emit('selection-changed',d)}"
    >
      <div slot="from-module">
        <el-row class="formContainer probox" type="flex" justify="space-around">
          <el-col>
            <h3 class="subTitle">账户信息</h3>
            <el-row class="form_Row_W100" type="flex">
              <el-col :span="1">账户金额</el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  :disabled="true"
                  placeholder
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="当前日期："
                  width="200px"
                  v-model="CardCompanyAccount.DateNow"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  :disabled="true"
                  placeholder
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="保证金总额："
                  width="200px"
                  v-model="CardCompanyAccount.AllAmount"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  :disabled="true"
                  placeholder
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="可用保证金："
                  width="200px"
                  v-model="CardCompanyAccount.CanAmount"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="95px"
                  form-type="input"
                  :disabled="true"
                  placeholder
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="冻结保证金："
                  width="180px"
                  v-model="CardCompanyAccount.FrostAmount"
                  type="text"
                ></znl-input>
              </el-col>
            </el-row>

            <!-- 购买的服务信息 -->
            <el-row class="form_Row_W100"
              type="flex"
              v-for="item in allServices"
              :key="item.ExtensionType">
              <el-col :span="1">{{item.Name}}</el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  :disabled="true"
                  placeholder
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="到期时间："
                  width="200px"
                  v-model="item.ExpirationTime"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  :disabled="true"
                  placeholder
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="购买条数："
                  width="200px"
                  v-model="item.BuyCnt"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  :disabled="true"
                  placeholder
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="已展示条数："
                  width="200px"
                  v-model="item.ViewCnt"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="95px"
                  form-type="input"
                  :disabled="true"
                  placeholder
                  size="mini"
                  layout="left"
                  :is-must-fill="false"
                  title="剩余可展示条数："
                  width="180px"
                  v-model="item.LeftCnt"
                  type="text"
                ></znl-input>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row class="formContainer nextbox" type="flex" justify="space-around">
          <el-col>
            <h3 class="subTitle">联系详情</h3>
            <el-row class="form_Row_W100" type="flex">
              <el-col :span="4">
                <znl-input
                  title-width="55px"
                  form-type="input"
                  placeholder="电话1"
                  size="mini"
                  layout="left"
                  :is-must-fill="true"
                  title="电话1："
                  width="200px"
                  :border="true"
                  :clearable="true"
                  v-model="CardLink.LinkTelephone1"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  placeholder="联系人1"
                  size="mini"
                  layout="left"
                  title="联系人1："
                  width="200px"
                  :border="true"
                  :clearable="true"
                  v-model="CardLink.LinkName1"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  placeholder="QQ1"
                  size="mini"
                  layout="left"
                  :clearable="true"
                  title="QQ1："
                  :border="true"
                  width="200px"
                  v-model="CardLink.QQ1"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  placeholder="QQ3"
                  size="mini"
                  layout="left"
                  title="QQ3："
                  width="200px"
                  :border="true"
                  :clearable="true"
                  v-model="CardLink.QQ3"
                  type="text"
                ></znl-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100" type="flex">
              <el-col :span="4">
                <znl-input
                  title-width="55px"
                  form-type="input"
                  placeholder="电话2"
                  size="mini"
                  layout="left"
                  :border="true"
                  title="电话2："
                  width="200px"
                  :clearable="true"
                  v-model="CardLink.LinkTelephone2"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  placeholder="联系人2"
                  size="mini"
                  layout="left"
                  title="联系人2："
                  width="200px"
                  :border="true"
                  :clearable="true"
                  v-model="CardLink.LinkName2"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  placeholder="QQ2"
                  size="mini"
                  layout="left"
                  title="QQ2："
                  width="200px"
                  :border="true"
                  :clearable="true"
                  v-model="CardLink.QQ2"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  placeholder="网址"
                  size="mini"
                  layout="left"
                  title="网址："
                  width="200px"
                  :border="true"
                  :clearable="true"
                  v-model="CardLink.WebSite"
                  type="text"
                ></znl-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100" type="flex">
              <el-col :span="4">
                <znl-input
                  title-width="55px"
                  form-type="input"
                  placeholder="电话3"
                  size="mini"
                  layout="left"
                  title="电话3："
                  :border="true"
                  width="200px"
                  :clearable="true"
                  v-model="CardLink.LinkTelephone3"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  placeholder="联系人3"
                  size="mini"
                  layout="left"
                  title="联系人3："
                  width="200px"
                  :clearable="true"
                  :border="true"
                  v-model="CardLink.LinkName3"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="11">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  placeholder="广告词"
                  size="mini"
                  layout="left"
                  title="广告词："
                  :border="true"
                  width="370px"
                  :clearable="true"
                  v-model="CardLink.AdvertWords"
                  type="text"
                ></znl-input>
              </el-col>
            </el-row>
            <el-row class="form_Row_W100" type="flex">
              <el-col :span="4">
                <znl-input
                  title-width="55px"
                  form-type="input"
                  placeholder="手机"
                  size="mini"
                  layout="left"
                  title="手机："
                  :border="true"
                  width="200px"
                  :clearable="true"
                  v-model="CardLink.LinkMobile"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="4">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  placeholder="联系人4"
                  size="mini"
                  layout="left"
                  title="联系人4："
                  width="200px"
                  :border="true"
                  :clearable="true"
                  v-model="CardLink.LinkName4"
                  type="text"
                ></znl-input>
              </el-col>
              <el-col :span="11">
                <znl-input
                  title-width="80px"
                  form-type="input"
                  placeholder="地址"
                  size="mini"
                  layout="left"
                  title="地址："
                  width="370px"
                  :border="true"
                  :clearable="true"
                  v-model="CardLink.CardAddr"
                  type="text"
                ></znl-input>
              </el-col>

              <znl-button style-type="main" :height='30' :width="90" @click="Save">
                <span>保存联系信息</span>
              </znl-button>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </znl-gridmodule>
  </znl-dialog>
</template>

<script>
import { mixin as znlMethodsMixin } from '@c_common/znlGrid/scripts/znlMethods'
import { mixin as moveToMixin } from '~assets/scripts/directives/moveTo'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
const CONFIG = {
  PK: 'StkInLineID',
  saveURL: 'PubAccountSet/AddCompanyContact',
  getCompanyContacURL: 'PubAccountSet/SearchCompanyContact',
  getAccountURL: 'CompanyAccount/GetInfo',
  getStkExtListURL: 'StkExtList/GetExtensionCnt',
  searchURL: 'AccountRecord/SearchRecord'// 搜索
}
export default {
  name: 'PubAccountSet',
  config: CONFIG,
  mixins: [znlMethodsMixin, moveToMixin, getCommonDataMixin],
  components: {

  },
  data () {
    return {
      dataMapSource: {
        //  记录类型:1.增加保证金,2.原装正品,3.保证有料,4.优势推广,5.退保证金,6.扣保证金,7.原装正品到期,8.保证有料到期,
        //  9.优势推广到期,10.云价格推广,11.云价格推广到期,12.现货认证,13.现货认证到期,14.正品条数,15.正品物料到期
        RecordType: [
          { key: 1, value: '添加保证金' },
          { key: 2, value: '原装正品' },
          { key: 3, value: '保证有料' },
          { key: 4, value: '优势推广' },
          { key: 5, value: '退保证金' },
          { key: 6, value: '扣保证金' },
          { key: 7, value: '原装正品到期' },
          { key: 8, value: '保证有料到期' },
          { key: 9, value: '优势推广到期' },
          { key: 10, value: '云价格推广' },
          { key: 11, value: '云价格推广到期' },
          { key: 12, value: '现货认证' },
          { key: 13, value: '现货认证到期' },
          { key: 14, value: '正品条数' },
          { key: 15, value: '正品物料到期' }
        ]
      },
      dialogCompanySelectVisible: false,
      loading: false,
      loadingText: '数据加载中,请稍后...',
      InTypes: '', // 1:客户，2：供应商，3:两者都是的公司
      CardLink: {  // 联系人对象
        LinkName1: '', // 联系人
        LinkName2: '', // 联系人
        LinkName3: '', // 联系人
        LinkName4: '', // 联系人
        LinkTelephone1: '', // 电话
        LinkTelephone2: '', // 电话
        LinkTelephone3: '', // 电话
        LinkMobile: '', // 手机
        CardAddr: '', // 地址
        QQ1: '', // QQ
        QQ2: '', // QQ
        QQ3: '', // QQ
        AdvertWords: '', // 广告
        Website: ''// 网址
      },
      allServices: [],
      serviceMapping: [
        {
          ExtensionType: 4,
          Name: '正品企业'
        },
        {
          ExtensionType: 8,
          Name: '正品物料'
        },
        {
          ExtensionType: 5,
          Name: '正品期货'
        },
        {
          ExtensionType: 6,
          Name: '保证有料'
        },
        {
          ExtensionType: 9,
          Name: '优势推广'
        },
        {
          ExtensionType: 3,
          Name: '云价格推广'
        }
      ],
      CardCompanyAccount: {  // 联系人对象
        DateNow: '', // 当前时间
        AllAmount: '', // 总保证金
        CanAmount: '', // 可用保证金
        FrostAmount: '' // 冻结保证金
      },
      filterFields: {
        RecordedTimeStart: '',
        RecordedTimeEnd: ''
      },
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      columns: [],
      itemSource: [],
      PaymentTypeOptions: [], // 类型
      AccountRecordlist: [], // 列表数据
      isPopup: false,
      bankAccount: [],
      showStocklist: false, // 是否显示选择库存页面
      refreshChecklist: false, // 是否刷新库存选择页面
      checkQty: false,
      fromSalesOrderOpen: false,
      fromBtns: [],
      fromSaveBtns: [{
        name: '保存联系信息',
        class: 'znl-btn znl-btn-main',
        click: () => { this.Save() }
      }
      ]
    }
  },
  props: {
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    visible: Boolean

  },
  watch: {
  },
  methods: {
    onInit: async function () {
      this.loading = true
      let config = [
        { CTitle: '流水类型', BindField: 'RType', IsShow: true, IsReadonly: false, ColumnWidth: 70, DisplayIndex: 1, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '记录时间', BindField: 'RecordedTime', IsShow: true, IsReadonly: false, ColumnWidth: 120, DisplayIndex: 2, DataType: 4, IsSystem: false, IsRequired: true },
        { CTitle: '金额', BindField: 'Amount', IsShow: true, IsReadonly: false, ColumnWidth: 70, DisplayIndex: 3, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '库存数量', BindField: 'Qty', IsShow: true, IsReadonly: false, ColumnWidth: 70, DisplayIndex: 4, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '备注', BindField: 'Remark', IsShow: true, IsReadonly: false, ColumnWidth: 200, DisplayIndex: 5, DataType: 1, IsSystem: false, IsRequired: true },
        { CTitle: '开始时间', BindField: 'StartTime', IsShow: true, IsReadonly: false, ColumnWidth: 72, DisplayIndex: 6, DataType: 4, IsSystem: false, IsRequired: true },
        { CTitle: '结束时间', BindField: 'EndTime', IsShow: true, IsReadonly: false, ColumnWidth: 72, DisplayIndex: 7, DataType: 4, IsSystem: false, IsRequired: true }
      ]
      let columns = this.flexGridColumnsHandler(config)
      // 记录员
      let UserAllowUsers = _.map(this.selectOptionsAllUserURL, function (obj, index) {
        return { key: obj.value, value: obj.value }
      })

      _.extend(
        _.find(columns, function (column) {
          return column.binding === 'Recordor'
        }),
        { dataMap: UserAllowUsers, showDropDown: true }
      )
      // 库存类型
      let stkTypeColumn = _.find(columns, column => column.name === 'RType')
      stkTypeColumn && _.extend(stkTypeColumn, {
        dataMap: this.dataMapSource.RecordType,
        showDropDown: true
      })

      this.columns = columns
      this.pageSize = 10
      let accountComp = await this.$post(CONFIG.getAccountURL)
      if (accountComp) {
        this.CardCompanyAccount.DateNow = this.crtTimeFtt(new Date())
        this.CardCompanyAccount.AllAmount = accountComp.Amount
        this.CardCompanyAccount.CanAmount = accountComp.UsableAmount
        this.CardCompanyAccount.FrostAmount = accountComp.FreezeAmount
      }
      let list = await this.$post(CONFIG.getStkExtListURL)
      // console.log(list)
      let newList = []
      if (list) {
        _.each(this.serviceMapping, item => {
          let serv = _.find(list, m => m.ExtensionType === item.ExtensionType)
          if (serv) {
            newList.push({
              Name: item.Name,
              ExtensionType: serv.ExtensionType,
              ExpirationTime: this.crtTimeFtt(serv.ExpirationTime),
              BuyCnt: serv.BuyCnt,
              ViewCnt: serv.ViewCnt,
              LeftCnt: serv.LeftCnt,
            })
          }
        })
        this.allServices = newList
      }
      let companyContact = await this.$post(CONFIG.getCompanyContacURL)
      if (companyContact) {
        this.CardLink.LinkTelephone1 = companyContact.LinkTelephone1
        this.CardLink.LinkTelephone2 = companyContact.LinkTelephone2
        this.CardLink.LinkTelephone3 = companyContact.LinkTelephone3
        this.CardLink.LinkName1 = companyContact.LinkName1
        this.CardLink.LinkName2 = companyContact.LinkName2
        this.CardLink.LinkName3 = companyContact.LinkName3
        this.CardLink.LinkName4 = companyContact.LinkName4
        this.CardLink.LinkMobile = companyContact.LinkMobile
        this.CardLink.CardAddr = companyContact.CardAddr
        this.CardLink.AdvertWords = companyContact.AdvertWords
        this.CardLink.QQ1 = companyContact.QQ1
        this.CardLink.QQ2 = companyContact.QQ2
        this.CardLink.QQ3 = companyContact.QQ3
        this.CardLink.Website = companyContact.Website
      }
      await this.onBindData(1)
      this.loading = false
    },
    crtTimeFtt (val) {
      if (val != null) {
        var date = new Date(val)
        let strDate = date.getDate()
        if (date.getDate() < 10) {
          strDate = '0' + date.getDate()
        }
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + strDate
      }
    },
    onSearch (params) {
      // console.log('1111', params, this.fieldsFilter)
      this.fieldsFilter = {}
      this.fieldsFilter = _.extend({}, this.fieldsFilter, params)
      return this.onBindData(1)
    },
    async onBindData (pageIndex = 1) {
      this.loadingText = '数据加载中,请稍后...'
      this.loading = true
      this.itemSource = this.AccountRecordlist
      let data = _.extend({}, {
        FieldWhereString: JSON.stringify(this.fieldsFilter),
        PageIndex: pageIndex,
        PageSize: this.pageSize
      }, {
          // 外部搜索条件
          // startReceiveTime: this.fieldsFilter.startReceiveTime,
          // endReceiveTime: this.fieldsFilter.endReceiveTime,
          // StartPaidTime: this.fieldsFilter.StartPaidTime,
          // EndPaidTime: this.fieldsFilter.EndPaidTime,
          // inputModel: this.fieldsFilter.inputModel
      })
      return await this.$post(CONFIG.searchURL, data, (data) => {
        this.pageIndex = pageIndex
        this.itemSource = data.ResultList
        this.totalCount = data.TotalCount
        this.loading = false
      })
    },
    Save () { // 保存并入库
      if (this.CardLink.LinkTelephone1 === undefined || this.CardLink.LinkTelephone1 === '') {
        return this.$message({ message: '请添加电话', type: 'error' })
      }
      var vm = this
      this.loadingText = '正在保存,请稍等...'
      vm.loading = true
      return this.$post(CONFIG.saveURL, this.CardLink, (data, logic) => {
        vm.loading = false
        if (logic.code === 200) {
          this.$message({ message: '保存成功', type: 'success' })
          this.$emit('close')
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      }).catch(e => {
        vm.loading = false
      })
    }

  },
  created () {
    // // 采购员列表
    // this.$post(CONFIG.getUserAllowUserURL, {}, (data) => {
    //   var users = data.Users
    //   this.BuyerOptions = _.map(users, items => { return { key: items.UserID, value: items.UserName } })
    // })
    // this.$post(CONFIG.BankAccountURL, {}, (data) => {
    //   this.bankAccount = _.map(data, items => { return { key: items.CBGUID, value: items.CBName } })
    // })
    // // 仓库
    // this.$post(CONFIG.getWarehouseURL, {}, (data) => {
    //   this.dataMapSourse.WarehouseOptions = _.map(data, items => { return { key: items.WarehouseName, value: items.WarehouseName } })
    // })
    // // 特殊权限
    // this.$post(CONFIG.getSpecialResource, data => {
    //   this.specialResource = data
    // })
  }
}
</script>
<style lang="scss">
.grid-pubaccountset-box {
  .base-flex-grid .table-container .el-table.isFieldsSearchLine {
    border-top: none !important;
  }

  .saveandout:hover {
    background-color: #fff7e7 !important;
  }
  .znl-from-module {
    margin: 0;
    border: 1px solid #bdbdbd;
    background: #fff;
    line-height: 30px;
  }
  h3.subtitle,
  h3.subTitle {
    margin-bottom: 0 !important;
    padding: 2px 8px;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
  }
  .znl-dialog__footer {
    display: none;
  }

  .probox {
    .znl-input-title {
      color: #888;
    }
    .el-col-1 {
      min-width: 70px;
      font-size: 12px;
      text-indent: 6px;
      text-align: right;
    }

    .el-input.is-disabled .el-input__inner {
      background-color: #fff;
      border-color: #e4e7ed;
      color: #333;
      cursor: not-allowed;
      border: none;
    }
  }
  .nextbox {
    .el-col-1 {
      min-width: 70px;
    }
    .el-textarea__inner {
      padding: 5px;
      margin-top: 10px;
    }
  }
  .znl-input-title {
    text-align: right;
  }
  .znl-component-input .znl-input-type .el-input__inner {
    text-align: left;
  }
  .form_Row_W100 {
    width: 100% !important;
  }
  .el-col-4 {
    min-width: 200px;
  }
  .znl-frombtns{
    height: 0 !important;
  }
}
</style>

