<template>
  <znl-layout-spa
    class="tab-fin-btnstyle-box grid-footer-right"
    :page-loading="pageLoading"
    height="100%"
    znl-loading-text="页面加载中,请稍后..."
  >
    <el-tabs
      value="PubStock"
      class="nav-common-ui tab-btnstyle-box oustslide_padding-right"
      v-show="!pageLoading"
      v-model="activeName"
      @tab-click="pageSearch"
    >
      <!-- **************************************************************************** -->
      <!-- **************************** v-table *************************************** -->
      <!-- **************************************************************************** -->
      <el-tab-pane label="推广库存(BOM.AI)" name="PubStkStock" height="100%">
        <znl-pub-stk-stock
          v-if="pubStockInitData"
          ref="pubStkStock"
          :init-data="true"
          @page-loading="setClientSelectLoading"
        ></znl-pub-stk-stock>
      </el-tab-pane>

      <el-tab-pane label="电商直供库存" v-if="false" name="PubSotckPricing" height="100%">
        <znl-pub-stock-pricing
          ref="pubSotckPricing"
          v-if="stockPricingInitData"
          :init-data="true"
          @page-loading="setClientSelectLoading"
        ></znl-pub-stock-pricing>
      </el-tab-pane>

      <el-tab-pane label="云价格推广" name="PubYunExtStock" height="100%">
        <znl-pub-yun-ext-stock
          v-if="yunInitData"
          ref="pubYunExtStock"
          :init-data="true"
          @page-loading="setClientSelectLoading"
        ></znl-pub-yun-ext-stock>
      </el-tab-pane>

      <el-tab-pane label="库存上传记录" name="PubStkStockImportHistory" height="100%">
        <znl-pub-stk-stock-import-history
          ref="pubStkStockImportHistory"
          v-if="historyInitData"
          :init-data="true"
          @page-loading="setClientSelectLoading"
        ></znl-pub-stk-stock-import-history>
      </el-tab-pane>

      <el-tab-pane label="定价存策略" v-if="false" name="PubPricingStrategy" height="100%">
        <znl-pub-pricing-strategy
          ref="PubPricingStrategy"
          v-if="pricingStrategy"
          :init-data="true"
          @page-loading="setClientSelectLoading"
        ></znl-pub-pricing-strategy>
      </el-tab-pane>
    </el-tabs>
    <!-- <a class="old-edition" @click="oldEdition()">切换到旧版</a> -->
  </znl-layout-spa>
</template>
<script>
import { switchToLegacyPage } from '~/lib/native'

import ZnlPubStkStock from '@c_modules/StkExt/PubStkStock_v1'
import ZnlPubYunExtStock from '@c_modules/StkExt/PubYunExtStock_v1'
import ZnlPubStkStockImportHistory from '@c_modules/StkExt/PubStkStockImportHistory_v1'
import znlPubStockPricing from '@c_modules/Trading/PubSotckPricing'
import znlPubPricingStrategy from '@c_modules/StkExt/PubPricingStrategy'

export default {
  name: 'PubStock',
  components: {
    switchToLegacyPage,
    ZnlPubStkStock,
    ZnlPubYunExtStock,
    ZnlPubStkStockImportHistory,
    znlPubStockPricing,
    znlPubPricingStrategy
  },
  data() {
    return {
      pageLoading: true,
      yunInitData: false,
      historyInitData: false,
      accountInitData: false,
      pubStockInitData: false,
      activeName: 'PubStkStock',
      stockPricingInitData: false,
      pricingStrategy: false
    }
  },
  methods: {
    async oldEdition() {
      await switchToLegacyPage('StockExtension')
    },
    pageSearch() {
      if (this.activeName === 'PubStkStock' && !this.pubStockInitData) {
        this.pubStockInitData = true
      } else if (this.activeName === 'PubSotckPricing' && !this.stockPricingInitData) {
        this.stockPricingInitData = true
      } else if (this.activeName === 'PubYunExtStock' && !this.yunInitData) {
        this.yunInitData = true
      } else if (this.activeName === 'PubStkStockImportHistory' && !this.historyInitData) {
        this.historyInitData = true
      } else if (this.activeName === 'PubPricingStrategy' && !this.pricingStrategy) {
        this.pricingStrategy = true
      }

    },
    setClientSelectLoading(val) {
      this.pageLoading = val
    },
  },
  created() {
    this.pageSearch()
  }
}
</script>
<style lang="scss">
</style>
