<template>
  <!-- 定价策略 -->
  <div class="module-wrapper">
    <!-- 表格组件 -->
    <pricing-strategy></pricing-strategy>
  </div>
</template>

<script>
const CONFIG = {
  configURL: 'SotckPricing/GetConfig',
  searchURL: 'StockSummary/Search',
}

import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { mixin as common } from '~assets/scripts/methods/common'

import PricingStrategy from './PricingStrategy/PricingStrategy'
export default {
  name: 'PubPricingStrategy',
  mixins: [getCommonDataMixin, common,],
  config: CONFIG,
  components: {
    PricingStrategy
  },
  data() {
    return {
      columns: [],
      itemSource: [],
      searchFields: {
        Model: ''
      },
      isShow: false,
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      loading: false,
      isShowFiedsSearch: false,
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
  },
  methods: {

    onInit: async function (config) {

    },

  },
  async mounted() {
    await this.onInit()
  },
}

</script>
