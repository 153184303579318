<template>
<!-- 更改展示类型 -->
<div>
  <znl-dialog :visible="visible&&dialogShow"
              width="300px"
              :height="height"
              class="stock-extend-set-box"
              :title="title"
              :on-init="onInit"
              v-loading="loading"
              :element-loading-text="loadingText"
              @close="()=>{this.dialogShow = false, this.isRefresh = !this.isRefresh, $emit('close')}">
    <div slot="znl-dialog" class="stock-extend-set">
      <el-row>
        <el-col>
          <el-row class="mleft">
            <el-col>
              <el-radio v-model="filterType" label="select" :fill="fillColor">按已选择 (已选中 {{checkedRows.length}} 条)</el-radio>
            </el-col>
            <el-col>
              <el-radio v-model="filterType" label="where" :fill="fillColor">按搜索条件 (共 {{filterRowCount}} 条)</el-radio>
            </el-col>
          </el-row>
        </el-col>
        <el-col class="mt20" v-if='isShowStype'>
          <el-row>
            <hr class="stk-hr">
          </el-row>
        </el-col>
      </el-row>
      <el-row v-if='isShowStype'>
        <el-col class="mt20">
          <el-row class="mleft">
            <el-col v-for="item in options" :key="item.key">
              <el-radio v-model="stype" :label="item.key">{{item.value}}</el-radio>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-row slot="footer">
      <znl-button style-type="main" :height='30' :width='60'
        :disabled="loading"
        @click="onConfirm"
        >
        <span>确认</span>
      </znl-button>
    </el-row>
  </znl-dialog>
</div>
</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'

export default {
  name: 'StockExtendSet',
  mixins: [propSyncMixin],
  components: {
  },
  data () {
    return {
      config: {
        GetServerItemURL: 'PubStkStock/GetServerItem',
        SetStockExtendURL: 'PubStkStock/SetSType',
        getCountURL: 'PubStkStock/GetCnt',
        setStockStatusUrl: 'PubStkStock/SetStockStatus',
        clearURL: 'PubStkStock/ClearExtStock',
        setIsSyncQtyUrl: 'PubStkStock/SetIsSyncQtyStatus'
      },
      pubStkStock: {
        PDateStart: '',
        PDateEnd: '',
        SType: '',
        Model: '',
        Brand: '',
        DeliveryDate: ''
      },
      dialogShow: false,
      isRefresh: false,
      options: [{ key: 4, value: '正品企业' }, { key: 6, value: '保证有料' }, { key: 8, value: '正品物料' }, { key: 5, value: '正品期货' }, { key: 9, value: '优势推广' }],
      stype: 4,
      stypeShow: { s4: false, s6: false, s9: false },
      fillColor: '#F70',
      filterType: 'select', // 设置条件 select: 已选择， where: 按搜索条件
      filterRowCount: 0,
      loading: false,
      loadingText: '正在加载，请稍后'
    }
  },
  props: {
    isExtedType: Number,
    height: String,
    title: String,
    isShowStype: {
      type: Boolean,
      default: true
    },
    dialogVisible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    visible: Boolean,
    checkedRows: { // 选中的行
      type: Array,
      default: () => { return [] }
    },
    searchCondition: { // 查询条件
      type: Object,
      default: () => { return {} }
    },
    checkedPSID: Array, // 选中的PSID
    checkedSType: Number // 选中的SType
  },
  watch: {
    async visible (val) {
      if (val) {
        if (this.isExtedType === 0) {
          await this.getServerItem()
        }
        this.dialogShow = true
        this.isRefresh = !this.isRefresh
        this.getCount()
      }
    }
  },
  methods: {
    onInit: async function () {
      // this.getServerItem()
    },
    onCancel () {
      this.p_dialogVisible = false
      this.$emit('close')
    },
    onConfirm () {
      if (this.filterType === 'select') {
        if (this.checkedRows.length === 0) {
          this.$message({ message: '请先选择库存', type: 'error' })
          return false
        }
        this.isEditType()
      } else {
        if (this.filterRowCount === 0) {
          this.$message({ message: '按查询条件共搜到0条库存，更改失败', type: 'error' })
          return false
        }

        if (this.filterRowCount > 2000) {
          this.$confirm('符合查询条件的库存为' + this.filterRowCount + '条，更新可能会花费较长时间。是否继续？', '提示', {
            confirmButtonText: '继续',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.isEditType()
            })
        } else {
          this.isEditType()
        }
      }
    },
    //  0.批量更改展示类型
    //  1. 批量展出
    //  2.批量取消
    //  3.批量删除
    //  4.批量设置同步
    //  5.批量取消同步
    isEditType () {
      if (this.isExtedType === 0) {
        this.confirmUpdate()
      } else if (this.isExtedType === 1) {
        this.batchDispaly(1)
      } else if (this.isExtedType === 2) {
        this.batchDispaly(0)
      } else if (this.isExtedType === 3) {
        this.batchDelete()
      } else if (this.isExtedType === 4) {
        this.setSyncStock(1)
      } else if (this.isExtedType === 5) {
        this.setSyncStock(0)
      }
    },

    setSyncStock (status) {
      let param = {
        UpdateType: this.filterType,
        IsSyncQty: status
      }
      if (this.filterType === 'select') {
        param.PSIDs = _.map(this.checkedRows, m => { return m.PSID })
      } else {
        param = _.extend(this.searchCondition, param)
      }
      let _this = this
      status === 1 ? _this.loadingText = '正在设置同步，请稍后' : _this.loadingText = '正在取消同步，请稍后'
      _this.loading = true
      this.$post(this.config.setIsSyncQtyUrl, param, (data, logic) => {
        _this.loading = false
        if (logic.code === 200 && data > 0) {
          this.$message({ message: '操作成功', type: 'success' })
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      }).then(() => {
        this.p_dialogVisible = false
        this.$emit('confirm', true)
      })
    },
    batchDelete () {
      let param = {
        UpdateType: this.filterType
      }
      if (this.filterType === 'select') {
        param.PSIDs = _.map(this.checkedRows, m => { return m.PSID })
      } else {
        param = _.extend(this.searchCondition, param)
      }
      let _this = this
      _this.loadingText = '正在刪除，请稍后'

      _this.loading = true
      this.$post(this.config.clearURL, param, (data, logic) => {
        _this.loading = false
        if (logic.code === 200 && data > 0) {
          this.$message({ message: '删除成功', type: 'success' })
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.$message({ message: '删除失败', type: 'error' })
        }
      }).then(() => {
        this.p_dialogVisible = false
        this.$emit('confirm', true)
      })
    },
    batchDispaly (newSStatus) {
      let param = {
        UpdateType: this.filterType,
        NewSStatus: newSStatus
      }
      if (this.filterType === 'select') {
        param.PSIDs = _.map(this.checkedRows, m => { return m.PSID })
      } else {
        param = _.extend(this.searchCondition, param)
      }
      let _this = this
      newSStatus === 1 ? _this.loadingText = '正在展示，请稍后' : _this.loadingText = '正在取消，请稍后'

      _this.loading = true
      this.$post(this.config.setStockStatusUrl, param, (data, logic) => {
        _this.loading = false
        if (logic.code === 200 && data > 0) {
          this.$message({ message: newSStatus === 1 ? '【' + data + '】条库存展示成功！' : '【' + data + '】条库存取消成功！', type: 'success' })
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.$message({ message: newSStatus === 1 ? '没有库存被展示' : '没有库存被取消', type: 'error' })
        }
      }).then(() => {
        _this.loading = false
        this.p_dialogVisible = false
        this.$emit('confirm', true)
      })
    },

    confirmUpdate () {
      let param = {
        UpdateType: this.filterType,
        NewSType: this.stype
      }
      if (this.filterType === 'select') {
        param.PSIDs = _.map(this.checkedRows, m => { return m.PSID })
      } else {
        param = _.extend(this.searchCondition, param)
      }
      let newSType = _.find(this.options, m => m.key === this.stype).value
      let _this = this
      _this.loadingText = '正在更改，请稍后'
      _this.loading = true
      this.$post(this.config.SetStockExtendURL, param, (data, logic) => {
        _this.loading = false
        if (logic.code === 200) {
          this.$message({ message: '已成功把 ' + data + ' 条库存的展示类型更改为 [' + newSType + ']', type: 'success' })
        } else if (this.hasValue(logic.msg)) {
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.$message({ message: '更改展示类型失败', type: 'error' })
        }
        this.p_dialogVisible = false
        this.$emit('confirm', true)
      })
    },

    // 获取服务
    async getServerItem () {
      let _this = this
      await this.$post(this.config.GetServerItemURL, {}, (data) => {
        if (data.length > 0) {
          _this.stypeShow.s4 = (_.some(data, item => item.ExtensionType === 4))
          _this.stypeShow.s6 = (_.some(data, item => item.ExtensionType === 6))
          _this.stypeShow.s9 = (_.some(data, item => item.ExtensionType === 9))

          // 设置默认值
          if (_this.stypeShow.s4) {
            _this.stype = 4
          } else if (_this.stypeShow.s6) {
            _this.stype = 6
          } else if (_this.stypeShow.s9) {
            _this.stype = 9
          }
        }
      })
    },
    async getCount () {
      this.filterRowCount = 0
      let param = {}
      param = _.extend(this.searchCondition, param)
      this.filterRowCount = await this.$post(this.config.getCountURL, param)
    }
  },
  created () {
    // this.getServerItem()
  }
}
</script>

<style lang="scss">
.mt20 {
  margin-top: 10px;
}
.stock-extend-set {
  .radios span {
    font-size: 12px;
  }
  .mleft {
    margin-left: 16px;
  }
  .col2 {
    margin-left: 20px;
  }
  .ml20 {
    margin-left: 20px;
  }
  .stk-hr {
    margin-top: 0;
    margin-bottom: 0;
  }
}
// .stock-extend-set-box .el-dialog{
//   transform: translate(0);
//   margin-top: -120px !important;
//   margin-left: -150px !important;
// }
</style>
