<template>
  <znl-dialog
    :visible="visible"
    :title="title"
    height="170px"
    width="300px"
    v-loading="dialogLoading"
    :close-on-click-modal="true"
    element-loading-text="请稍后..."
    @close="()=>{$emit('close')}">
    <div slot="znl-dialog" class="m-yun-stock-batch-show">
      <el-row>
        <el-radio v-model="filterType" label="select" :fill="fillColor">按已选择 (已选中 {{checkedRows.length}} 条)</el-radio>
      </el-row>
      <el-row>
        <el-radio v-model="filterType" label="where" :fill="fillColor">按搜索条件 (共 {{filterRowCount}} 条)</el-radio>
      </el-row>
    </div>
    <el-row slot="footer">
      <znl-button style-type="main" :height='30' :width='60'
        :disabled="processing"
        @click="onConfirm"
        >
        <span>确认</span>
      </znl-button>
    </el-row>
  </znl-dialog>
</template>

<script>
const CONFIG = {
  getCountUrl: 'PubYunExtStock/GetCount',
  setStockStatusUrl: 'PubYunExtStock/SetYunExtStockStatus',
  deleteYunExtStockUrl: 'PubYunExtStock/DeleteYunExtStock'
}

export default {
  name: 'pub-yun-ext-stock-batch-show',

  data () {
    return {
      filterRowCount: 0,
      dialogLoading: false,
      processing: false,
      filterType: 'select',
      fillColor: ''
    }
  },

  methods: {
    async getCount () {
      this.dialogLoading = true
      let count = await this.$post(CONFIG.getCountUrl, this.searchCondition)
      this.filterRowCount = count
      this.dialogLoading = false
    },

    async onConfirm () {
      if (this.filterType === 'select') {
        if (this.checkedRows === null || this.checkedRows === undefined || this.checkedRows.length === 0) {
          this.$message({ message: '请先选择库存', type: 'error' })
          return false
        }
        this.searchCondition.YEIDs = _.map(this.checkedRows, m => m.YEID)
      } else {}

      this.searchCondition.UpdateType = this.filterType

      if (this.operateType === 'status') {
        this.onSetStatus()
      } else if (this.operateType === 'delete') {
        this.onDelete()
      }
    },

    async onSetStatus () {
      this.processing = true
      this.$post(CONFIG.setStockStatusUrl, this.searchCondition, (data, logic) => {
        this.processing = false
        if (logic.code !== 200) {
          logic.msg = logic.msg || (this.searchCondition.NewSStatus ? '批量展示失败': '批量取消失败')
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          let msg = this.searchCondition.NewSStatus ? '批量展示成功': '批量取消成功'
          this.$message({ message: msg, type: 'success' })
          this.$emit('close', true)
        }
      })
    },

    async onDelete () {
      let length = this.filterType === 'select' ? this.checkedRows.length : this.filterRowCount
      let isok = await this.$confirm('确认删除' + length + '条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      if (!isok) {
        return false
      }

      this.processing = true
      this.$post(CONFIG.deleteYunExtStockUrl, this.searchCondition, (data, logic) => {
        this.processing = false
        if (logic.code !== 200) {
          logic.msg = logic.msg || '批量删除失败'
          this.$message({ message: logic.msg, type: 'error' })
        } else {
          this.$message({ message: '批量删除成功', type: 'success' })
          this.$emit('close', true)
        }
      })
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },

    // 勾选的行
    checkedRows: {
      type: Array,
      default: () => {
        return []
      }
    },

    // 查询条件
    searchCondition: {
      type: Object,
      default: () => {
        return {}
      }
    },

    operateType: {
      type: String,
      default: 'status'
    }
  },

  watch: {
    visible (val) {
      if (val) {
        this.getCount()
      }
    }
  },

  computed: {
    title () {
      if (this.operateType === 'delete') {
        return '批量删除'
      }
      if (this.searchCondition.NewSStatus) {
        return '批量展示'
      } else {
        return '批量取消'
      }
    }
  }
}
</script>

<style lang="scss">
.m-yun-stock-batch-show{
  .el-row {
    margin-left: 16px;
  }
}
.el-message-box__wrapper {
  z-index: 9999 !important;
}
</style>
