<template>
  <div>
    <znl-dialog
      :visible="visible&&dialogShow"
      width="570px"
      height="520px"
      title="上传推广库存"
      class="pub-stock-import"
      @close="()=>{this.dialogShow = false, this.isRefresh = !this.isRefresh, $emit('close')}"
    >
      <div slot="znl-dialog" style="padding-left: 12px;line-height:26px">
        <el-row>
          <el-col>
            <el-row>
              <!-- 1. 上传成功后展示类型的优先级为：现货认证 > 优势推广；如需更改展示类型请前往正能量ERP库存推广模块 -->
              <!-- 1. <a href="https://bom-ai-read.oss-cn-shenzhen.aliyuncs.com/makesureFile/ImportTemp.csv" target="_blank">点此下载Excel文件模板</a> -->
              1.
              <a href="javascript:void(0)" @click="downLoadExtTemp()">点此下载Excel文件模板</a>
            </el-row>
          </el-col>
          <el-col class="mt20">
            <el-row>2. 严格按照模板文件的格式填写库存信息（
              <a href="javascript:void(0)" @click="showRule(1)">点击查看各列内容的检测与过滤规则</a>）
            </el-row>
          </el-col>

          <el-col class="mt20">
            <el-row>3. 选择推广类型：</el-row>
          </el-col>
          <el-col class="mt20 pubstype">
            <el-row>
              <el-radio-group
                v-model="stype"
                size="small"
                @change="onSTypeChange"
                class="form-group"
              >
                <el-radio :label="4" border v-show="stypeShow.s4">正品企业</el-radio>
                <el-radio :label="8" border v-show="stypeShow.s8">正品物料</el-radio>
                <el-radio :label="5" border v-show="stypeShow.s5">正品期货</el-radio>
                <el-radio :label="6" border v-show="stypeShow.s6">保证有料</el-radio>
                <el-radio :label="9" border v-show="stypeShow.s9">优势推广</el-radio>
              </el-radio-group>
            </el-row>
            <el-row v-if="stype === 5">
              <span class="tips">(温馨提示：上传正品期货库存时，“货期”和“官方报价”必填。)</span>
            </el-row>
          </el-col>

          <el-col>
            <el-row>4. 库存匹配规则
              <el-tooltip
                class="tooltips-item"
                effect="light"
                v-if="isAutoUpdateExt"
                content="自动更新推广库存已打开。如果要更改匹配规则，请在ERP快捷版，设置 -> 系统参数设置 -> 推广库存更新设置,里面进行修改."
                placement="right"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </el-row>
          </el-col>
          <el-col class="mt20">
            <el-row class="mleft" v-if="!isAutoUpdateExt">
              <el-checkbox v-model="stockRules.rule_1" @change="onRuleChange(1)" size="small">&nbsp;
                <span>相同
                  <span class="bold-span">型号、品牌、封装</span> 的库存视为同一条库存
                </span>
              </el-checkbox>
              <el-checkbox
                v-model="stockRules.rule_2"
                @change="onRuleChange(2)"
                style="margin-left: 0 !important;"
                size="small"
              >&nbsp;
                <span>相同
                  <span class="bold-span">型号、品牌、封装、年份</span> 的库存视为同一条库存
                </span>
              </el-checkbox>
            </el-row>
            <el-row class="mleft" v-if="isAutoUpdateExt">
              <el-checkbox
                v-model="stockRules.rule_1"
                style="margin-left: 0 !important;"
                class="normal-color"
                :disabled="true"
                size="small"
              >&nbsp;
                <span>相同
                  <span class="bold-span">型号、品牌、封装{{stockRule === 2? '、年份': ''}}</span> 的库存视为同一条库存
                </span>
              </el-checkbox>
            </el-row>
          </el-col>

          <el-col class="mt20">
            <el-row>5. 上传模式
              <!-- （<a href="javascript:void(0)" @click="showRule(2)">说明</a>） -->
            </el-row>
          </el-col>
          <el-col class="mt20">
            <el-row class="mleft">
              <el-checkbox
                v-model="mode_recover"
                @change="onModeChange(1)"
                size="small"
              >&nbsp;[覆盖模式]：会先清空您选择的推广类型中的原有库存，重新导入本次上传的库存。</el-checkbox>
              <el-checkbox
                v-model="mode_replace"
                @change="onModeChange(2)"
                style="margin-left: 0 !important;"
                size="small"
              >&nbsp;[更换模式]：不清空原有库存，按照您选择的推广类型和库存匹配规则替换和新增库存。</el-checkbox>
            </el-row>
          </el-col>
          <!-- <el-col class="mt20" style="margin-top: 20px;">
          <el-row>
              <v-upload ref="vUpload"
              accept=".csv"
              width="303px"
              :refresh="!isRefresh"
              :refresh-click="refreshClick"
              @upload-before="onUploadClick"
              @upload-completed="onUploadCompleted"
              />
          </el-row>
          </el-col>-->
        </el-row>
        <el-row>
          <el-col class="mt20">
            <el-row>6. 选择文件并上传：</el-row>
          </el-col>
          <el-col class="mt20">
            <el-row>
              <v-upload
                ref="vUpload"
                accept=".csv"
                width="343px"
                :refresh="!isRefresh"
                :refresh-click="refreshClick"
                @upload-before="onUploadClick"
                @upload-completed="onUploadCompleted"
              />
            </el-row>
          </el-col>
          <el-col>
            <el-row style="font-size:10px; color:#2A2A2A">
              <span style="color:red;">*</span>导入的库存将替换系统中现有的优势库存或现货库存
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-row slot="footer" v-show="false">
        <el-button type="default" :disabled="loading" @click="onCancel" class="znl-btn-auxiliary">关闭</el-button>
      </el-row>
    </znl-dialog>
    <v-import-rule
      ref="vImportRule"
      :visible="ruleDialogShow"
      :height="showTypeHeight"
      :width="showTypeWidth"
      :title="showTitle"
      :showType="showType"
      @close="()=>{ruleDialogShow=false}"
    ></v-import-rule>
  </div>
</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import vUpload from '@c_modules/StkExt/Upload'
import vImportRule from '@c_modules/StkExt/PubStkStockImportRule'

export default {
  name: 'PubStkStockImport',
  mixins: [propSyncMixin, getCommonDataMixin],
  components: {
    vUpload,
    vImportRule
  },
  data() {
    return {
      config: {
        // uploadURL: 'https://jsonplaceholder.typicode.com/posts/'
        GetServerItemURL: 'PubStkStock/GetServerItem',
        progressUrl: 'PubStkStockImport/Uploaded'
      },
      dialogShow: false,
      ruleDialogShow: false,
      isRefresh: false,
      stype: 0,
      stypeShow: { s4: false, s6: false, s9: false, s5: false, s5: false },
      uploadMode: '1',
      showType: 1,
      showTitle: '',
      showTypeWidth: '800px',
      showTypeHeight: '510px',
      fileList: [],
      mode_recover: true,
      mode_replace: false,
      stockRule: 1, // 库存匹配规则（1.型号，品牌，封装；  2.型号，品牌，封装，年份）
      stockRules: {
        rule_1: true,
        rule_2: false
      },
      isAutoUpdateExt: false, // 是否自动更新推广库存
      refreshClick: false // 刷新上传组件的上次的单击动作
    }
  },
  props: {
    height: String,
    loading: Boolean,
    dialogVisible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    visible: Boolean
  },
  watch: {
    visible(val) {
      if (val) {
        this.dialogShow = true
        // this.stype = '4'
        this.uploadMode = '1'
        this.isRefresh = !this.isRefresh
      }
    }
  },
  methods: {
    async onInit() {
      await this.getServerItem()
      this.isAutoUpdateExt = this.getCompanySetupValue('AutoUpdateExt') === '1'
      let matchRule = this.getCompanySetupValue('ExtendStockMathRule')
      if (this.isAutoUpdateExt && matchRule === 'B') {
        this.stockRule = 2
      } else {
        this.stockRule = 1
      }
    },
    isNull(val) {
      if (val === null || val === undefined || val === '') {
        return true
      } else {
        return false
      }
    },
    showRule(type) {
      this.showType = type
      if (type === 1) {
        this.showTypeWidth = '800px'
        this.showTypeHeight = '510px'
      } else {
        this.showTypeWidth = '480px'
        this.showTypeHeight = '400px'
      }
      this.ruleDialogShow = true
    },
    onRuleChange(rule) {
      this.stockRule = rule
      this.stockRules.rule_1 = (rule === 1)
      this.stockRules.rule_2 = (rule === 2)
    },
    downLoadExtTemp() {
      var url = 'https://bom-ai-read.oss-cn-shenzhen.aliyuncs.com/makesureFile/ImportTemp.csv'
      this.downFile({ FileName: '上传推广库存模板.csv', FileUrl: url },
        function (res) {
          // console.log(res)
        }
      )
    },
    onCancel() {
      this.p_dialogVisible = false
      // this.$emit('confirm', false)
      this.$emit('close')
    },
    onSTypeChange(stype) {
    },
    onModeChange(val) {
      if (val === 1) {
        this.mode_recover = true
        this.mode_replace = false
        this.uploadMode = '1'
      } else {
        this.mode_recover = false
        this.mode_replace = true
        this.uploadMode = '2'
      }
    },
    async getServerItem() {
      let vm = this
      await this.$post(this.config.GetServerItemURL, {}, (data) => {
        if (data.length > 0) {
          _.each(data, item => {
            let key = 's' + item.ExtensionType
            vm.stypeShow[key] = true
          })

          if (vm.stypeShow.s4) {
            vm.stype = 4
          } else if (vm.stypeShow.s8) {
            vm.stype = 8
          }else if (vm.stypeShow.s5) {
            vm.stype = 5
          } else if (vm.stypeShow.s6) {
            vm.stype = 6
          } else if (vm.stypeShow.s9) {
            vm.stype = 9
          }
        }
      })
    },
    // 单击上传按钮的时候
    onUploadClick(fileName, fileSize) {
      if (fileName === '' || fileName === null || _.isUndefined(fileName)) {
        return this.$message({ message: '请选择一个CSV格式的文件', type: 'error' })
      }
      if (fileName.lastIndexOf('.csv') < 1) {
        return this.$message({ message: '请选择一个CSV格式的文件', type: 'error' })
      }

      if (this.stype === undefined || this.stype === null || this.stype === 0) {
        return this.$message({ message: '请选择推广类型。(如果没有，请购买推广服务)', type: 'error' })
      }

      this.refreshClick = !this.refreshClick
    },
    // 文件上传完成之后
    onUploadCompleted(fileName, fileSize, fileUrl) {
      return this.$post(this.config.progressUrl, { SType: this.stype, StockFileUrl: fileUrl, UploadMode: this.uploadMode, StockRule: this.stockRule }, data => {
        if (data >= 0) {
          this.$message({ message: '文件上传成功，请到库存上传记录查看详情', type: 'success' })
          this.$emit('upload-success', true)
        } else {
          this.$message({ message: '上传失败', type: 'error' })
        }
      })
    }
  },
  mounted() {
    this.onInit()
  },
  created() {
    // this.getServerItem()
  }
}
</script>

<style lang="scss">
.upload {
  margin-top: 10px;
}
.mt20 {
  margin-top: 8px;
}
.radios span {
  font-size: 12px;
}
.mleft {
  margin-left: 16px;
}
.pub-stock-import {
  .bold-span {
    font-weight: bolder;
  }
  .tooltips-item {
    color: gray;
  }
  .normal-color {
    .el-checkbox__input.is-disabled + span.el-checkbox__label {
      color: #333;
    }
  }

  .tips {
    font-size: 12px;
    color: red;
  }
  .pubstype {
    height: 55px;
  }
  .el-radio{
    margin-right: 0px;
  }
}
</style>
