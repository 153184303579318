<template>
  <page-layout title="定价策略" class="p-PricingStrategy">
    <div class="p-PricingStrategy-title">
      <div class="p-PricingStrategy-title-bg"></div>
      <znl-button type="text" class="p-PricingStrategy-help" @click="showPricingHelp=true">定价帮助</znl-button>
    </div>
    <div class="p-PricingStrategy-header">销售渠道分类</div>

    <div class="p-PricingStrategy-table">
      <znl-gridmodule
        height="auto"
        :on-init="onInit"
        :item-source="datas"
        :columns="columns"
        :is-fields-search="false"
        :show-header="false"
        :cell-line-height="50"
      ></znl-gridmodule>
      <znl-button :width="100" :height="30" @click="setShareDiscountAsync" class="save-button">保存</znl-button>
    </div>

    <div class="p-PricingStrategy-header">阶梯价</div>

    <div class="p-PricingStrategy-tiered-pricing">
      <price-card
        v-for="(item, index) in tieredPricings"
        :key="index"
        :price-data="item"
        :get-datas="getShareStepPlanListAsync"
        :show-delete="tieredPricingsWithGuid.length > 1"
        class="p-PricingStrategy-tiered-pricing-item"
      ></price-card>

      <div class="p-PricingStrategy-tiered-pricing-item addnew" @click="addTieredPricing">
        <p>
          <i class="iconfont icon-add_round"></i>
        </p>新建方案
      </div>
    </div>
    <pricing-help :visible.sync="showPricingHelp" :show-checked="showChecked"></pricing-help>
  </page-layout>
</template>
<script>
import { Columns } from './columns';
import PriceCard from './PriceCard';
import PricingHelp from './PricingHelp';
import PageLayout from './PageLayout'
export default {
  name: 'PricingStrategy',
  data() {
    return {
      datas: [],
      columns: [],
      tieredPricings: [],
      showPricingHelp: false,
      showChecked: false
    };
  },
  components: {
    PriceCard,
    PricingHelp,
    PageLayout
  },
  created() {
    this.getShareStepPlanListAsync();
  },
  computed: {
    tieredPricingsWithGuid() {
      return this.tieredPricings.filter(item => item.StepPlanGuid);
    }
  },
  methods: {
    addTieredPricing() {
      // 最多10个阶梯价
      if (this.tieredPricings.length >= 10) {
        return this.$message({
          message: '最多允许保存10个方案',
          type: 'error'
        })
      }
      // id轮排
      let StepPlanId = this.tieredPricings.length + 1;
      const StepPlanIdArray = this.tieredPricings
        .map(item => item.StepPlanId)
        .sort((a, b) => b - a);
      for (let index = 1; index < StepPlanIdArray[0]; index++) {
        const HasStepPlanId = StepPlanIdArray.find(item => item === index);
        if (!HasStepPlanId) {
          StepPlanId = index;
          break;
        }
      }
      this.tieredPricings.push({
        IsEdit: true,
        StepPlanId,
        StepPlanSummary: '',
        StepPlanPrices: [
          {
            Qty: '',
            PriceMultiple: ''
          }
        ]
      });
    },
    async onInit() {
      this.columns = Columns;
      this.getShareDiscountListAsync();
    },
    getShareDiscountListAsync() {
      this.$post('SotckPricing/GetShareDiscountList', null, (res, logic) => {
        if (logic.code === 200) {
          this.datas = res
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      })
    },
    setShareDiscountAsync() {
      this.$post('SotckPricing/SetShareDiscount', this.datas, (res, logic) => {
        if (logic.code === 200) {
          this.$message({
            message: '设置成功',
            type: 'success'
          })
          this.getShareDiscountListAsync();
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      })
    },

    getShareStepPlanListAsync() {
      this.$post('SotckPricing/GetShareStepPlanList', null, (res, logic) => {
        if (logic.code === 200) {
          const Datas = res || [];
          for (const item of Datas) {
            item.IsEdit = false;
          }
          this.tieredPricings = Datas;
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      });
    }
  }
};
</script>

<style lang="scss">
.p-PricingStrategy {
  .p-PricingStrategy-title {
    height: 245px;
    border: 1px solid #ccc;
    position: relative;
    .p-PricingStrategy-title-bg {
      position: absolute;
      width: 886px;
      height: 207px;
      background: url("./imgs/pricing-rule@2x.png");
      background-size: 100% 100%;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
    .p-PricingStrategy-help {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }
  .p-PricingStrategy-header {
    line-height: 30px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
    font-weight: bold;
    background-color: #e6e6e6;
    margin-top: 20px;
    padding-left: 10px;
  }
  .save-button {
    margin-left: 125px;
  }
  .p-PricingStrategy-table {
    width: 300px;
  }
  .cell-input {
    width: 30px;
  }
  .el-table,
  .el-table td,
  .el-table th.is-leaf {
    border: none;
  }
  .znl-grid-component,
  .el-table,
  .znl-cell-header .header-label,
  .znl-table-header_element-cell.table-selection .cell::before,
  .el-table th,
  .el-table tr,
  .el-table th > .cell,
  .znl-content .base-flex-grid,
  .znl-btn-text.default,
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: transparent !important;
    background: transparent !important;
  }
  .znl-table_base-case,
  .znl-cell-header .header-label,
  .el-table {
    border: none !important;
  }
  .el-table--border::after,
  .el-table--group::after {
    width: 0;
  }
  .el-table::before {
    height: 0;
  }
  .el-table {
    .znl-current-cell .znl-tablecell {
      border: none;
      padding: 0 5px;
      line-height: 50px !important;
      background-color: transparent;
    }
    .el-table__body tr.current-row > td {
      background-color: transparent;
    }
  }

  .znl-component-input .el-input__inner {
    padding-left: 5px;
  }

  .p-PricingStrategy-tiered-pricing {
    .p-PricingStrategy-tiered-pricing-item {
      margin-right: 20px;
      margin-top: 20px;
      &.addnew {
        width: 210px;
        height: 307px;
        border: 1px solid #e6e6e6;
        padding: 10px;
        box-sizing: border-box;
        display: inline-block;
        background-color: #fff;
        text-align: center;
        color: #ced0da;
        font-size: 16px;
        padding-top: 100px;
        .iconfont {
          font-size: 50px;
        }
      }
    }
  }

  .znl-tablecell,
  .znl-table_element-cell {
    height: 50px !important;
    line-height: 50px !important;
  }
  .znl-current-cell .znl-tablecell {
    border: none !important;
  }
}
</style>
