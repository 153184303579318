<template>
  <znl-dialog
    :visible="visible"
    :append-to-body="true"
    title="定价帮助"
    class="c-PricingHelp"
    width="664px"
    @close="$emit('update:visible', false)"
  >
    <div>
      <div class="c-PricingHelp-bg"></div>
      <h4>最终售价公式：</h4>
      <p>标准价*阶梯价*渠道折扣=最终售价</p>
      <h4>例：</h4>
      <p>假设您在库存定价将物料LM358的标准价设为：1元</p>
      <p>阶梯价方案一设置为：1+ 1.5倍</p>
      <p class="balckspace">10+ 1.4倍</p>
      <p class="balckspace">100+ 1.3倍</p>
      <p>A类销售渠道折扣为：9折</p>
      <p>则通过A类销售渠道购买10片LM358的价格为：1*1.4*0.9=1.26元</p>
    </div>
    <div slot="footer" class="c-PricingHelp-footer clearfix">
      <znl-button
        :width="80"
        :height="30"
        style-type="main"
        @click="() => {isActionHandler();$emit('update:visible', false)}"
      >好的</znl-button>
      <el-checkbox v-model="isAction" class="pull-right" v-if="showChecked">不再弹出</el-checkbox>
    </div>
  </znl-dialog>
</template>
<script>
export default {
  name: 'PricingHelp',
  data() {
    return {
      isAction: false
    };
  },
  props: {
    visible: Boolean,
    showChecked: Boolean
  },
  methods: {
    isActionHandler() {
      if (!this.showChecked) {
        return;
      }
      const params = {
        key: 'showPricingHelp',
        value: this.isAction
      };
      this.$post('mmieaction/SaveUpdate', params).then(response => {
        if (response.Code === 0) {
          return null;
        }
      });
    }
  }
};
</script>
<style lang="scss">
.c-PricingHelp {
  h4,
  p {
    margin: 0;
  }
  .c-PricingHelp-bg {
    width: 624px;
    height: 210px;
    background: url("./imgs/pricing-rule_mini@2x.png");
    background-size: 100% 100%;
  }
  .znl-dialog__body {
    padding-top: 0;
  }
  .balckspace {
    padding-left: 140px;
  }
  .c-PricingHelp-footer {
    text-align: center;
  }
}
</style>

