<template>
<!-- 推广库存 -->
  <znl-table
    ref="table"
    gridtype="base"
    :columns="columns"
    :item-source="itemSource"
    :on-page-changed="onBindData"
    :page-size="pageSize"
    :page-index="pageIndex"
    :total-count="totalCount"
    :is-fields-search="isShowFiedsSearch"
    v-loading="loading"
    element-loading-text="数据加载中,请稍后..."
    :show-title-menus="true"
    :header-menus="getResetTableHeaderMenu('PubStkStock_Mini')"
    @on-show-trigger="onHideShowColTrigger"
    :drag-done="onDragDone"
    @drag-col-position="(val)=>{onSaveConfig(val,true)}"
    @on-config-save="(val)=>{onSaveConfig(val,false)}"
    @on-reset-click="onResetTableConfigClick"
  >
  </znl-table>
</template>
<script>
import { mixin as getCommonDataMixin } from '~assets/scripts/methods/getCommonData'
import { openLinkAll } from '@scripts/methods/common'

const CONFIG = {
  configURL: 'PubStkStockMini/GetConfig',
  saveConfigURL: 'PubStkStockMini/SaveConfig',
  resetConfigURL: 'PubStkStockMini/ResetConfig',
  searchURL: 'PubStkStockMini/GetPubSpotStocks'
}
export default {
  name: 'PubStkStock_Mini',
  mixins: [getCommonDataMixin,openLinkAll], // 第二个方法需要添加后才能监控到外部条件改变也可以刷新页面
  config: CONFIG,
  components: { },
  data () {
    return {
      actionBtns: [],
      resourceList: [],
      columns: [],
      itemSource: [],
      Size: 5,
      pageIndex: 1,
      totalCount: 0,
      role: '1',
      loading: false,
      isShowFiedsSearch: false,
      searchFields: {
      },
      stkInquiry: {// 询价参数传递
        SupplierName: '', // 供应商名称
        Model: '', // Model
        Brand: '',
        Packaging: '',
        MakeYear: '',
        Quality: '',
        DeliveryDate: '',
        InvQty: 0,
        Qty: 0,
        MPQ: ''
      },
      // 绑定推广类型
      // STypeOptions: [
      //   { key: 4, value: '正品企业' },
      //   { key: 6, value: '保证有料' },
      //   { key: 9, value: '优势推广' }
      // ],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount,
    }
  },
  computed: {
    pageSize: {
      get () {
        return this.isMini ? 5 : this.Size
      },
      set (val) {
        this.Size = val
      }
    }
  },
  props: {
    initData: {
      tayp: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isMini: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    // selectOptionsWarehouseOptions (val) {
    //   _.each(this.columns, column => {
    //     if (column.binding === 'WarehouseOptions ') {
    //       this.$set(column, 'dataMap', val)
    //     }
    //   })
    // }
  },
  async mounted () {
    await this.onInit()
  },
  methods: {
    async onInit (config) {
      if (!config) {
        config = await this.isSaveStorage('PubStkStock_Mini', CONFIG.configURL)
      }

      // 获取模块打开或者关闭的状态
      let columns = config.ColumnConfigs
      var colIndex = _.findIndex(columns, item => item.BindField === 'StockType')
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: 'select',
          width: 80,
          options: [
          { key: '正品企业', value: 4 },
          { key: '保证有料', value: 6 },
          { key: '优势推广', value: 9 },
          ]
        })

      }
      // _.each(columns, (column, index) => {
      //   if (column.BindField === 'Model' ||
      //     column.BindField === 'PartNo' ||
      //     column.BindField === 'SupplierName' ||
      //     column.BindField === 'CustomerName' ||
      //     column.BindField === 'BillNo') {
      //     _.extend(column,
      //     {
      //       className: 'znl-linkAll-column',
      //       renderCell: this.openLinkAll(column)
      //     })
      //   }
      // })
      let tempConfig = [
        {
          width: 40,
          type: 'button',
          title: '操作',
          btnTxt: '询价',
          visible: true,
          isDeal: false,
          isFrozen: true,
          click: row => {
            this.onInquiry(row)
          }
        }
      ]
      columns = tempConfig.concat(columns)
      this.columns = columns
      this.actionBtns = []
      this.role = config.Role
      this.pageSize = config.PageSize
      this.$refs.table.init()
      this.initData && this.onBindData()
    },
    onBindData (pageIndex) {
      //this.$refs.table.cleanSort()
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.searchFields),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        { Model: this.searchModel.Model }
      )
      return this.$post(CONFIG.searchURL, data, data => {
        this.pageIndex = pageIndex
        if (_.isUndefined(data.ResultList)) {
          this.itemSource = []
        } else {
          this.itemSource = data.ResultList || []
        }
        this.totalCount = data.TotalCount
        this.loading = false
        //this.$emit('page-loading', false)
      })
    },
    onHeadSearch (params) {
      this.searchFields = params
      this.searchModel.Model = params.Model
      this.onBindData(1)
    },
    hasRes (code) {
      return _.some(this.resourceList, page => page === code)
    },
    // 如果下拉按钮全部隐藏，则主按钮也隐藏
    async onRefresh () {
      await this.onBindData(this.pageIndex)
    },
    onInquiry: async function (row) { // 询价
      if (row) {
        let price = ''
        if (this.hasValue(row.UnitPriceText)) { price = this.toFloat(row.UnitPriceText.replace('￥', ''), 6) }
        this.$nextTick(function () {
          this.stkInquiry.SupplierName = row.SupplierName
          this.stkInquiry.Model = row.PartNo
          this.stkInquiry.Brand = row.Brand
          this.stkInquiry.Packaging = row.Packaging
          this.stkInquiry.MakeYear = row.MakeYear
          this.stkInquiry.Quality = row.Quality
          this.stkInquiry.DeliveryDate = row.DeliveryDate
          this.stkInquiry.InvQty = row.Qty
          this.stkInquiry.Price = price
          this.stkInquiry.QQ = row.QQ
          this.stkInquiry.Telephone = row.Telephone
          this.stkInquiry.Contact = row.Contact
          this.addInquire(this.stkInquiry, function (msg) {
            if (msg === '不能向自己询价') {
              this.$message({ message: msg, type: 'warning' })
            }
          })
        })
      }
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("PubStkStock_Mini", e.col)
    },
    onDragDone(col) {
      this.setTableColWidth("PubStkStock_Mini", col)
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols
      this.saveErpTableConfig("PubStkStock_Mini", cols, isDelay)
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("PubStkStock_Mini")
    }
  }
}
</script>

<style lang="scss">
</style>
