<template>
  <znl-dialog
    :visible="visible"
    :title="title"
    height="600px"
    width="850px"
    v-loading="dialogLoading"
    :close-on-click-modal="false"
    :element-loading-text="LoadingText"
    @close="()=>{$emit('close')}">
    <znl-table
        gridtype="action"
        ref="table"
        slot="znl-dialog"
        height="100%"
        checkbox-binding-key="_tempid"
        :showCheck="true"
        :on-init="onInit"
        :columns="columns"
        :item-source="itemSource">
        <div slot="from-module"></div>
        <div slot="header" class="v-table-toolbar">
          <znl-button style-type="mac" @click="() => {isShowCopyFromExcel = true}">
            <i class="iconfont icon-copy_btn_ic"></i>
            <span>从Excel粘贴</span>
          </znl-button>
          <znl-button style-type="mac" @click="onAddRow()">
            <i class="iconfont icon-add_btn_ic"></i>
            <span>新增</span>
          </znl-button>
          <znl-button style-type="mac" @click="onDeletedRows()">
            <i class="iconfont icon-delete_btn_ic"></i>
            <span>删除</span>
          </znl-button>
        </div>
    </znl-table>

    <el-row slot="footer" style="margin-top:15px;">
      <znl-button :disabled="loading" :height="33" :width="60"  @click="onCancel">关闭</znl-button>
      <znl-button style-type="main" :height="33" :width="60" :loading="loading"  @click="onSubmit">{{loading?'导入中':'导入'}}</znl-button>
    </el-row>
    <!-- 粘贴数据 -->
    <paste-data-dialog
      :visible="isShowCopyFromExcel"
      @close="() => {isShowCopyFromExcel = false}"
      @add-excel-data="addExcelData"
    >
    </paste-data-dialog>
  </znl-dialog>
</template>

<script>
import { mixin as propSyncMixin } from '@scripts/mixins/propSync'
import { renderRequiredColumn } from '~assets/scripts/methods/common'
import PasteDataDialog from '@c_modules/StkExt/PasteDataDialog'

export default {
  name: 'PubYunExtStockAdd',
  mixins: [propSyncMixin, renderRequiredColumn],
  components: {
    PasteDataDialog
  },
  data () {
    return {
      fromBtns: [

      ],
      config: {
        configURL: 'PubYunExtStockAdd/GetConfig',
        saveConfigURL: 'PubYunExtStockAdd/SaveConfig',
        resetConfigURL: 'PubYunExtStockAdd/ResetConfig',
        saveLineURL: 'PubYunExtStockAdd/AddLine'
      },
      itemSource: [],
      columnColors: [],
      rowColors: [],
      editRowIndex: -1,
      summaryColumns: [],
      columns: [],
      dialogLoading: false,
      isShowCopyFromExcel: false,
      LoadingText: '',
      znlid: -1
    }
  },
  props: {
    height: String,
    loading: Boolean,
    dialogVisible: {
      type: Boolean,
      default: false,
      propsync: true
    },
    visible: Boolean,
    title: String
  },
  watch: {
    visible (val) {
      if (val === true) {
        this.itemSource = []
      }
    }
  },
  methods: {
    onInit: async function (config) {
      if (!config) {
        config = await this.isSaveStorage('PubYunExtStockAdd', this.config.configURL)
      }
      // let columns = this.flexGridColumnsHandler(config.ColumnConfigs)
      // columns = this.renderRequiredColumn(columns)
      let columns = config.ColumnConfigs
      columns = this.renderRequiredColumn(columns)

      this.columns = columns

      this.fromBtns = [
        {
          name: '从Excel粘贴',
          isShow: true,
          iconName: 'icon-copy_btn_ic',
          click: () => { this.isShowCopyFromExcel = true }
        },
        {
          name: '新增',
          isShow: true,
          iconName: 'icon-add_btn_ic',
          click: () => { this.$refs.flexGrid.addRow(true) }
        },
        {
          name: '删除',
          isShow: true,
          iconName: 'icon-delete_btn_ic',
          click: () => { this.onDeletedRows() }
        }
      ]
      this.defaultConfig = config
      this.pageSize = config.PageSize
      this.resourceList = config.ResourceList.map(item => item.Code)// 权限
      this.role = config.Role
      this.$refs.table.init()

      this.initData && this.onBindData()
    },

    onAddRow () {
      this.znlid = this.znlid - 1
      let row = {
        _tempid: this.znlid
      }
      console.log(row)
      this.itemSource.push(row)
    },

    onDeletedRows () {
      let rows = this.$refs.table.getCheckedRows()
      console.log(rows)
      if (rows.length === this.itemSource.length) {
        this.itemSource = []
        return true
      }
      let datas = this.itemSource
      let index = -1
      rows.forEach(row => {
        index = datas.findIndex(m=> m._tempid === row._tempid)
        if (index > -1) {
          datas.splice(index, 1)
        }
      })

      this.itemSource = datas
    },

    // 从excel粘贴
    addExcelData (rows) {
      this.dialogLoading = true
      this.LoadingText = '请稍后...'
      if (rows && rows.length > 0) {
        let datas = this.itemSource
        rows.forEach(row => {
          this.znlid = this.znlid - 1
          row._tempid = this.znlid
          datas.push(row)
        })
        this.itemSource = datas
      }
      this.isShowCopyFromExcel = false
      this.dialogLoading = false
    },

    onSubmit () {
      let vm = this
      let rows = this.itemSource
      if (rows.length > 0) {
        let rowId = 0
        let messageStr = ''
        _.some(rows, item => {
          rowId = rowId + 1
          if (this.isEmpty(item['Model'])) {
            messageStr = '第' + rowId + '行 型号不能为空'
            return true
          }
          if (this.isEmpty(item['Brand'])) {
            messageStr = '第' + rowId + '行 品牌不能为空'
            return true
          }
          if (this.isEmpty(item['Packaging'])) {
            messageStr = '第' + rowId + '行 封装不能为空'
            return true
          }
          if (this.isEmpty(item['Qty'])) {
            messageStr = '第' + rowId + '行 数量不能为空'
            return true
          }
          if (this.isEmpty(item['DeliveryDate'])) {
            messageStr = '第' + rowId + '行 货期不能为空'
            return true
          }
          if (this.isEmpty(item['Quality'])) {
            messageStr = '第' + rowId + '行 品质不能为空'
            return true
          }
          if (this.isEmpty(item['Price'])) {
            messageStr = '第' + rowId + '行 价格不能为空'
            return true
          }
          if (!isFinite(item['Qty'])) {
            messageStr = '第' + rowId + '行 数量必须为数值型'
            return true
          }
          // if (!isFinite(item['Price'])) {
          //   messageStr = '第' + rowId + '行 价格必须为数值型'
          //   return true
          // }
        })
        if (messageStr.length > 0) {
          return this.$message({ message: messageStr, type: 'error' })
        }
      } else {
        // return this.$message({ message: '请输入待导入的数据', type: 'error' })
      }
      this.dialogLoading = true
      this.LoadingText = '正在导入，请稍等...'
      vm.$post(this.config.saveLineURL, {'LineList': rows}, (data) => {
        vm.p_dialogVisible = false
        this.dialogLoading = false
        vm.$message({ message: '导入完成, 总共 ' + (rows.length) + ' 条，已导入' + (data) + '条，出错' + (rows.length - data) + '条', type: 'success' })
        vm.$emit('confirm', true)
      })
    },

    onCancel () {
      this.p_dialogVisible = false
      this.visible = false
      this.$emit('confirm', false)
    },

    isEmpty (val) {
      if (val === null || val === undefined || val === '') {
        return true
      }
      return false
    }
  },

  async mounted () {
    await this.onInit()
  }
}
</script>
