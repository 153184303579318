<template>
<znl-dialog
    height="400px"
    width="800px"
    title="上传失败原因"
    class="dialog-grid-footer-box"
    :visible="visible"
    :footer-height="0"
    @close="()=>{$emit('close',false)}">
     <znl-gridmodule
        ref="flexGrid"
        :is-init="true"
        :grid-show="true"
        :columns="columns"
        :item-source="itemSource"
        :column-colors="columnColors"
        :row-colors="rowColors"
        :summary-columns="summaryColumns"
        :is-fields-search="false"
        :has-znl-actions="false"
        :page-size="pageSize"
        :page-index="pageIndex"
        :total-count="totalCount"
        :role="role"
        :on-init="onInit"
        :on-page-changed="onBindData">
  </znl-gridmodule>
  <el-row slot="footer" style="display: none;">
  </el-row>
</znl-dialog>

</template>
<script>
import {mixin as znlMethodsMixin} from '@c_common/znlGrid/scripts/znlMethods'

const CONFIG = {
}

export default {
  name: 'OperateLog',
  mixins: [znlMethodsMixin],
  config: CONFIG,
  components: {
  },
  data () {
    return {
      columns: [],
      itemSource: [],
      editRowIndex: -1,
      filterFields: {},
      fieldsFilter: {},
      searchFields: {},
      columnColors: [],
      rowColors: [],
      summaryColumns: [],
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
      role: '1'
    }
  },
  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel:
    {
      type: Object,
      default: () => {
        return {
          OrderGuid: ''
        }
      }
    },
    height: String,
    width: String,
    failListJson: String,
    refresh: Boolean,
    visible: Boolean
  },
  watch: {
    refresh: {
      handler (value) {
        if (!_.isEmpty(this.orderGuid)) {
          this.onBindData()
        }
      },
      immediate: true
    },
    failListJson: {
      handler (value) {
        if (!_.isEmpty(value)) {
          this.onBindData()
        }
      },
      immediate: true
    }
  },
  methods: {
    async onInit () {
      let columns = []
      columns.push({
        'name': 'index',
        'dataType': 1,
        'binding': 'index',
        'width': 50,
        'header': '序号',
        'visible': true,
        'allowSorting': false,
        'isReadOnly': true,
        'isFieldsSearch': false,
        'isSystem': true
      }, {
        'name': 'content',
        'dataType': 1,
        'binding': 'content',
        'width': 'auto',
        'header': '详情',
        'visible': true,
        'allowSorting': false,
        'isReadOnly': true,
        'isFieldsSearch': false,
        'isSystem': true
      })

      this.columns = columns
      this.showDeleteRowRightMenu = false
      this.showSaveRowRightMenu = false

      this.onBindData()
    },
    onBindData (pageIndex = 1) {
      this.itemSource = []
      if (this.failListJson !== undefined && this.failListJson !== null && this.failListJson !== '') {
        let list = this.failListJson.split(';')
        this.pageIndex = pageIndex
        this.pageSize = this.pageSize
        this.totalCount = list.length
        let start = (pageIndex - 1) * this.pageSize
        let end = start + this.pageSize - 1
        for (let i = 0; i < list.length; i++) {
          if (list[i] === '') {
            this.totalCount = this.totalCount - 1
            continue
          }
          if (i < start || i > end) {
            continue
          }
          this.itemSource.push({
            index: i + 1,
            content: list[i]
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>

