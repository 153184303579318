<template>
  <div class="c-PriceCard">
    <div class="c-PriceCard-header clearfix">
      <div class="title pull-left">方案{{NumToCharacters(priceData.StepPlanId)}}</div>
      <div class="pull-right edit-button" v-if="!comPiceData.IsEdit && allowEdit">
        <znl-button
          type="text"
          v-if="showDelete"
          class="colorred"
          @click="() => deleteShareStepPlanAsync(comPiceData)"
        >删除</znl-button>
        <znl-button type="text" @click="priceData.IsEdit=true">编辑</znl-button>
      </div>
      <div class="pull-right" v-if="comPiceData.IsEdit && allowEdit">
        <znl-button type="text" @click="cancelHandler">取消</znl-button>
      </div>
    </div>
    <div class="c-PriceCard-body">
      <div class="explain" v-if="!comPiceData.IsEdit">{{comPiceData.StepPlanSummary}}</div>

      <div v-else class="explain">
        <znl-input
          width="100%"
          :border="true"
          :height="20"
          placeholder="备注说明"
          v-model="comPiceData.StepPlanSummary"
        ></znl-input>
      </div>

      <ul class="list-header">
        <li class="clearfix">
          <div class="pull-left cell cell-header">梯度</div>
          <div class="pull-left cell cell-header">标准价倍率</div>
        </li>
      </ul>
      <ul class="list-body" :class="[comPiceData.IsEdit ? 'IsEdit' : '']">
        <li class="clearfix" v-for="(item, index) in comPiceData.StepPlanPrices" :key="index">
          <div class="pull-left cell cell-body">
            <i
              v-if="comPiceData.IsEdit"
              @click="() => deleteHandler(comPiceData.StepPlanPrices, item)"
              class="iconfont icon-close pull-left"
            ></i>
            <span v-if="!comPiceData.IsEdit">{{item.Qty}}</span>
            <znl-input v-else width="50px" :height="20" :border="true" v-model="item.Qty"></znl-input>
            <span>&nbsp;+</span>
          </div>
          <div class="pull-left cell cell-body">
            <span v-if="!comPiceData.IsEdit">{{item.PriceMultiple}}</span>
            <znl-input v-else width="30px" :height="20" :border="true" v-model="item.PriceMultiple"></znl-input>
            <span>&nbsp;倍</span>
          </div>
        </li>
        <li
          v-if="comPiceData.IsEdit"
          class="add-price-row"
          @click="() => addHandler(comPiceData.StepPlanPrices)"
        >
          <span>+</span>
        </li>
      </ul>

      <div v-if="comPiceData.IsEdit" class="textcenter footer">
        <znl-button style-type="main" :width="80" :height="20" @click="saveHandler">保存</znl-button>
      </div>
    </div>
  </div>
</template>
<script>
import { NumToCharacters } from './columns';
export default {
  props: {
    priceData: {
      type: Object,
      default() {
        return {};
      }
    },
    getDatas: Function,
    showDelete: {
      type: Boolean,
      default: true
    },
    allowEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      comPiceData: {},
      NumToCharacters
    };
  },
  created() {
    this.transformHandler();
  },
  watch: {
    priceData: {
      deep: true,
      handler() {
        this.transformHandler();
      }
    }
  },
  methods: {
    transformHandler() {
      if (this.priceData.StepPlanPrices) {
        this.priceData.StepPlanPrices.forEach((item, index) => {
          item.index = index;
        });
      }
      this.comPiceData = JSON.parse(JSON.stringify(this.priceData));
    },
    cancelHandler() {
      this.priceData.IsEdit = false;
      if (this.getDatas) {
        this.getDatas();
      }
    },
    saveHandler() {
      this.$post('SotckPricing/SaveShareStepPlan', this.comPiceData, (res, logic) => {
        if (logic.code === 200) {
          this.$message({ message: '保存成功', type: 'success' })
          if (this.getDatas) {
            this.getDatas();
          }
        } else {
          this.$message({ message: logic.msg, type: 'error' })
        }
      })
    },
    addHandler(StepPlanPrices) {
      StepPlanPrices.push({
        Qty: '',
        PriceMultiple: '',
        index: StepPlanPrices.length
      });
    },
    deleteHandler(StepPlanPrices, item) {
      StepPlanPrices.forEach((data, index) => {
        if (data.index === item.index) {
          StepPlanPrices.splice(index, 1);
        }
      });
    },
    ConfirmHandler(tip, item, statu) {
      this.$confirm(tip, '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.changeGuaranteeOrderStatus(item, statu);
      });
    },
    deleteShareStepPlanAsync(comPiceData) {
      this.$confirm('是否删除该方案？', '提醒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        var param = {
          StepPlanGuid: comPiceData.StepPlanGuid
        }
        this.$post(`SotckPricing/DeleteShareStepPlan`, param, (res, logic) => {
          if (logic.code === 200) {
            this.$message({ message: '删除成功', type: 'success' })
            if (this.getDatas) {
              this.getDatas();
            }
          } else {
            this.$message({ message: logic.msg, type: 'error' })
          }
          // if (res.Code === 0 && res.Data) {
          // } else if (res.Code === 2) {
          //   this.$confirm('有库存正在使用该方案，请替换其他方案后删除', '提醒',
          //     {
          //       confirmButtonText: '替换方案',
          //       cancelButtonText: '取消'
          //     }
          //   ).then(() => {
          //     // 跳转库存定价并筛选方案
          //     // this.$router.push({
          //     //   name: 'InventoryPricing',
          //     //   params: { StepPlanGuid: this.comPiceData.StepPlanGuid }
          //     // });
          //   });
          // } else {
          //   this.$message({
          //     message: res.Message,
          //     type: 'error'
          //   })
          // }
        });
      });
    }
  }
};
</script>
<style lang="scss">
.c-PriceCard {
  width: 200px;
  height: 307px;
  border: 1px solid #e6e6e6;
  padding: 10px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  background-color: #fff;
  font-size: 12px;
  .colorred {
    color: #ff2200;
    &.znl-btn-text.default .button {
      color: #ff2200;
    }
  }
  .textcenter {
    text-align: center;
  }
  .edit-button {
    display: none;
  }
  &:hover {
    .edit-button {
      display: block;
    }
  }
  .c-PriceCard-header {
    .title {
      line-height: 24px;
      font-weight: bold;
      color: #000;
    }
  }
  .cell-header {
    color: rgba(0, 0, 0, 0.65);
    background-color: #e6f0fa;
    font-weight: bold;
  }
  .c-PriceCard-body {
    .explain {
      line-height: 26px;
      color: rgba(0, 0, 0, 0.45);
    }
    .list-body {
      height: 200px;
      overflow-y: auto;
      &.IsEdit {
        height: 180px;
      }
    }
    .cell {
      width: 50%;
      text-align: center;
      line-height: 30px;
      color: #000;
      margin-top: 3px;
      &.cell-body {
        .icon-close {
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    .footer {
      padding-top: 10px;
    }
    .add-price-row {
      text-align: center;
      cursor: pointer;
      border: 1px solid #dbdbdb;
      width: 90px;
      height: 20px;
      line-height: 20px;
      margin: 0 auto;
      border-radius: 2px;
      margin-top: 10px;
      span {
        display: inline-block;
        width: 14px;
        height: 14px;
        background-color: #ced0da;
        border-radius: 50%;
        color: #fff;
        font-weight: bold;
        line-height: 14px;
      }
    }
  }
}
</style>
