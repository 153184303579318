<template>
  <!-- 推广的库存 -->
  <div class="module-wrapper">
    <znl-table
      ref="table"
      gridtype="action"
      checkboxBindingKey="PSID"
      :showCheck="true"
      :columns="columns"
      :item-source="itemSource"
      :on-page-changed="onBindData"
      :page-size="pageSize"
      :page-index="pageIndex"
      :total-count="totalCount"
      :is-fields-search="isShowFiedsSearch"
      :search-fields="onSearch"
      v-loading="loading"
      element-loading-text="数据加载中,请稍后..."
      @select-change="selectChange"
      :show-title-menus="true"
      :header-menus="getResetTableHeaderMenu('PubStkStock')"
      @on-show-trigger="onHideShowColTrigger"
      :drag-done="onDragDone"
      @drag-col-position="(val)=>{onSaveConfig(val,true)}"
      @on-config-save="(val)=>{onSaveConfig(val,false)}"
      @on-reset-click="onResetTableConfigClick"
    >
      <div slot="header" class="v-table-toolbar search-box">
        <el-row>
          <znl-input
            form-type="input"
            placeholder="请输入型号"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="150px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="pubStkStock.Model"
            type="text"
          ></znl-input>

          <znl-input
            form-type="input"
            placeholder="请输入品牌"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="100px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="pubStkStock.Brand"
            type="text"
          ></znl-input>

          <znl-input
            form-type="input"
            placeholder="请输入货期"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="80px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="pubStkStock.DeliveryDate"
            type="text"
          ></znl-input>

          <znl-input
            form-type="select"
            placeholder="展示类型"
            :clearable="true"
            @change="val =>{pubStkStock.SType = val.key, onSearch()}"
            :select-options="selectOptionsSType"
            width="100px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="pubStkStock.SType"
            type="text"
          ></znl-input>

          <znl-input
            form-type="select"
            placeholder="展示状态"
            :clearable="true"
            :select-options="selectOptionsSStatus"
            @change="val => { pubStkStock.SStatus = val.key, onSearch()}"
            width="80px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="pubStkStock.SStatus"
            type="text"
          ></znl-input>

          <znl-input
            form-type="datepicker"
            placeholder="上传日期从"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="105px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="pubStkStock.PDateStart"
            type="text"
          ></znl-input>
          <znl-input
            form-type="datepicker"
            placeholder="上传日期止"
            :clearable="true"
            @keyup.enter.native="onSearch()"
            width="105px"
            size="mini"
            layout="left"
            inp-perc="100%"
            :border="true"
            v-model="pubStkStock.PDateEnd"
            type="text"
          ></znl-input>

          <znl-button type="multiple" :height="22" style-type="mac" class="box-left-btn search-btn">
            <znl-button-menu @click="onSearch()">
              <i class="iconfont icon-search_ic"></i>
              <span>搜索</span>
            </znl-button-menu>
            <znl-button-menu :width="20" tip="高级搜索" @click="onShowFiedsSearch()">
              <i class="iconfont icon-arrow-drop-down_ic"></i>
            </znl-button-menu>
          </znl-button>
        </el-row>
        <el-row>
          <znl-button style-type="mac" :height="22" @click="openUpdateExtendType(1)">
            <i class="iconfont icon-send_btn"></i>
            <span>批量展示</span>
          </znl-button>

          <znl-button style-type="mac" :height="22" @click="openUpdateExtendType(2)">
            <i class="iconfont icon-backout_btn_ic"></i>
            <span>批量取消</span>
          </znl-button>

          <znl-button style-type="mac" :height="22" @click="openUpdateExtendType(3)">
            <i class="iconfont icon-bomlist_delete_btn"></i>
            <span>批量删除</span>
          </znl-button>

          <znl-button style-type="mac" :height="22" @click="openUpdateExtendType(0)">
            <i class="iconfont icon-change_btn_ic"></i>
            <span>更改展示类型</span>
          </znl-button>

          <znl-button style-type="main" class="save-self-btn" @click="onBatchSave()">
            <i class="iconfont icon-save_btn_ic"></i>
            <span>保存修改</span>
          </znl-button>

          <znl-button style-type="main" :height="22" @click="batchImport2" tip="按搜索条件">
            <i class="iconfont icon-leading-in-wh_btn_ic"></i>
            <span>上传库存</span>
          </znl-button>

          <znl-button
            style-type="mac"
            :btns="printznlbtns"
            :height="22"
            @click="onExport"
            tip="按搜索条件"
          >
            <i class="iconfont icon-leading-out-wh_btn_i"></i>
            <span>导出库存</span>
          </znl-button>

          <znl-button style-type="default" :height="22" @click="openAccountSet()">
            <i class="iconfont icon-table_set_btn_n"></i>
            <span>账号设置</span>
          </znl-button>

          <!-- <znl-float-list :lists="actionBtns" class="znl-float-list-stock-type">
            <znl-button :height="22" style-type="mac">
              <i class="iconfont icon-left_nav_show_btn"></i>
              <span>更多操作</span>
            </znl-button>
          </znl-float-list>-->
        </el-row>
      </div>
      <div slot="footer" class="v-table-footer-total">
        <el-row>
          <el-col :span="24">
            <span v-for="(item, key) in SumSearchList" :key="key">
              {{showStypeText(item)}} :
              <span
                class="stsPurchaseIn-amount"
              >{{item.ViewCnt}} / {{item.BuyCnt}} 条</span>&nbsp;&nbsp;
            </span>
          </el-col>
        </el-row>
      </div>
    </znl-table>

    <!-- 上传库存 -->
    <v-pub-stock-import
      ref="vPubStockImport"
      :visible="pubStockImportVisible"
      @close="()=>{pubStockImportVisible=false}"
      @upload-success="(val)=>{pubStockImportVisible=!val}"
      @confirm="(v)=>{pubStockImportVisible=false,v&&this.getTotal()}"
    ></v-pub-stock-import>

    <!-- 批量设置 -->
    <update-extend-type
      :visible="stockExtendVisible"
      :checked-rows="selectedRows"
      :title="title"
      :height="heights"
      :isExtedType="isExtedType"
      :isShowStype="isShowStype"
      :search-condition="searchCondition"
      @close="()=>{stockExtendVisible=false}"
      @confirm="(v)=>{stockExtendVisible=false,this.onSearch()&&this.getTotal()}"
    ></update-extend-type>

    <!-- 账号设置 -->
    <znl-pub-account-set
      :init-data="false"
      v-if="pubAccountSetVisible"
      :visible="pubAccountSetVisible"
      :searchModel="accountSearchModel"
      @close="()=>{pubAccountSetVisible=false}"
    ></znl-pub-account-set>

    <no-service-warning v-if="!hasService"></no-service-warning>

    <export-column
      :visible="setExportColumnVisible"
      :addColumns="addColumnsList"
      :addConfigColumns="defaultConfig"
      :saveConfigURL="setSaveConfigURL"
      v-if="setExportColumnVisible"
      title="设置导出列"
      @close="()=>{setExportColumnVisible=false}"
      @confirm="(v)=>{setExportColumnVisible=false}"
    ></export-column>
  </div>
</template>
<script>
import vPubStockImport from "@c_modules/StkExt/PubStkStockImport";
import updateExtendType from "@c_modules/StkExt/UpdateExtendType";
import ZnlPubAccountSet from "@c_modules/StkExt/PubAccountSet";
import NoServiceWarning from "@c_modules/StkExt/NoServiceWarning";

import { mixin as getCommonDataMixin } from "~assets/scripts/methods/getCommonData";
import ExportColumn from "@c_modules/Crm/setExportColumn";
import {
  exportData,
  erpTableSetHandler,
  itemsHandle
} from "@scripts/methods/common";

const CONFIG = {
  PK: "MTGuid",
  configURL: "PubStkStock/GetConfig",
  saveConfigURL: "PubStkStock/SaveConfig",
  searchURL: "PubStkStock/Search",
  setStockStatusUrl: "PubStkStock/SetStockStatus",
  setIsSyncQtyUrl: "PubStkStock/SetIsSyncQtyStatus",
  clearURL: "PubStkStock/ClearExtStock",
  setSTypeUrl: "PubStkStock/SetSType",
  editStockUrl: "PubStkStock/EditStock",
  hasServiceUrl: "PubStkStock/HasService",
  SumSearchListUrl: "PubStkStock/SumSearch"
  // SumSearchListUrl: 'StkExtList/GetExtensionCnt',
};
export default {
  name: "PubStkStock",
  mixins: [getCommonDataMixin, exportData, erpTableSetHandler, itemsHandle],
  config: CONFIG,
  components: {
    vPubStockImport,
    updateExtendType,
    ZnlPubAccountSet,
    NoServiceWarning,
    ExportColumn
  },
  data() {
    return {
      actionBtns: [],
      title: "更改展示类型",
      heights: "150px",
      isShowStype: true,
      isExtedType: 0,
      isShowFiedsSearch: false,
      columns: [],
      itemSource: [],
      pubStkStock: {
        PDateStart: "",
        PDateEnd: "",
        SType: "",
        Model: "",
        Brand: "",
        DeliveryDate: ""
      },
      fieldsFilter: {},
      pageSize: 30,
      pageIndex: 1,
      totalCount: 0,
      extensionType: "",
      dialogtitle: "",
      loading: false,
      // 绑定推广类型
      STypeOptions: [
        { key: "正品企业", value: 4 },
        { key: "正品物料", value: 8 },
        { key: "保证有料", value: 6 },
        { key: "正品期货", value: 5 },
        { key: "优势推广", value: 9 },
        { key: "云价格推广", value: 3 }
      ],
      SStatusOptions: [
        { key: "未展出", value: false },
        { key: "已展出", value: true }
      ],
      resourceList: [], // 权限列表
      hasService: false,
      officalPriceParam: {
        Model: "",
        Brand: "",
        MakeYear: "",
        Packaging: "",
        StepPrice: ""
      },
      accountSearchModel: {
        data: {},
        extra: {}
      },
      pubStockImportVisible: false,
      pubAccountSetVisible: false,
      SStatus: null,
      SumSearchList: [], // 统计集合,
      stockExtendVisible: false,
      searchCondition: {}, // 查询条件
      checkedPSID: [], // 选中的推广库存的ID
      checkedSType: 0, // 选中的展示类型
      printznlbtns: [
        {
          name: "设置导出列",
          // iconName: 'icon-table_set_btn_n',
          click: () => {
            this.showExportColumn();
          }
        }
      ],
      setExportColumnVisible: false,
      addColumnsList: [],
      defaultConfig: {},
      setSaveConfigURL: "",
      selectedRows: [],
      isMainAccount: this.$store.state.accountInfo.IsMainAccount
    };
  },

  props: {
    initData: {
      type: Boolean,
      default: false
    },
    searchModel: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  async created() {
    let exists = await this.$post(CONFIG.hasServiceUrl, {});
    if (exists === 1) {
      this.hasService = true;
    } else {
      this.hasService = false;
    }
  },
  async mounted() {
    await this.onInit();
  },
  methods: {
    onSaveRows(rows) {
      if (rows.length > 0) {
        return this.$post(CONFIG.editStockUrl, rows, (data, logic) => {
          if (logic.code === 200) {
            this.$message({ message: "保存成功", type: "success" });
            this.$refs.table.cleanEdit();
          } else {
            this.$message({ message: logic.msg, type: "error" });
          }
        }).finally(() => {});
      }
    },
    onInit: async function(config) {
      if (!config) {
        config = await this.isSaveStorage("PubStkStock", CONFIG.configURL);
      }
      if (
        this.hasValue(config.ResourceList) &&
        config.ResourceList.length > 0
      ) {
        this.resourceList = config.ResourceList.map(item => item.Code);
      }
      let columns = config.ColumnConfigs;

      let colIndex = _.findIndex(columns, item => item.BindField === "SType");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 80,
          // isFrozen: true,
          options: this.STypeOptions,
          isReadOnly: true
        });
      }
      colIndex = _.findIndex(columns, item => item.BindField === "SStatus");
      if (colIndex > -1) {
        _.extend(columns[colIndex], {
          type: "select",
          width: 70,
          // isFrozen: true,
          options: this.SStatusOptions,
          isReadOnly: true
        });
      }
      // colIndex = _.findIndex(columns, item => item.BindField === 'Model')
      // if (colIndex > -1) {
      //   _.extend(columns[colIndex], {
      //     // isFrozen: true,
      //   })
      // }
      this.columns = columns;
      this.defaultConfig = config;
      this.pageSize = config.PageSize > 30 ? this.pageSize : config.PageSize;
      this.actionBtns = [
        {
          name: "批量展出",
          // isShow: this.hasRes('Show'),
          click: () => {
            this.openUpdateExtendType(1);
          }
        },
        {
          name: "批量取消",
          // isShow: this.hasRes('Cancel'),
          click: () => {
            this.openUpdateExtendType(2);
          }
        },
        {
          name: "批量删除",
          // isShow: this.hasRes('Delete'),
          click: () => {
            this.openUpdateExtendType(3);
          }
        },
        {
          name: "设置联系方式",
          // isShow: this.hasRes('Add'),
          click: () => {
            this.openAccountSet();
          }
        }
      ];
      this.$refs.table.init();
      //this.$emit('page-loading', false)
      if (this.initData && this.hasService) {
        this.onBindData();
      }
      this.$emit("page-loading", false);
    },

    getTotal() {
      if (this.pageIndex === 1) {
        this.$post(CONFIG.SumSearchListUrl, data => {
          this.SumSearchList = data;
        });
      }
    },
    onBindData(pageIndex = 1) {
      if (!this.hasService) {
        this.$emit("page-loading", false);
        return this.$message({
          message: "你尚未开通库存推广服务，请联系正能量客服人员",
          type: "error"
        });
      }
      //this.$refs.table.cleanSort()
      this.loading = true;
      let data = this.getSearchWhere(pageIndex);
      return this.$post(CONFIG.searchURL, data, data => {
        this.removeTime(data.ResultList, ["ExpireDate"]);
        this.pageIndex = pageIndex;
        this.itemSource = data.ResultList;
        this.totalCount = data.TotalCount;
        this.$emit("page-loading", false);
        this.loading = false;
        this.getTotal();
        this.$refs.table.cleanEdit();
      });
    },
    getSearchWhere(pageIndex) {
      let data = _.extend(
        {},
        {
          FieldWhereString: JSON.stringify(this.fieldsFilter),
          PageIndex: pageIndex,
          PageSize: this.pageSize
        },
        {
          // 外部搜索条件
          PDateStart: this.pubStkStock.PDateStart,
          PDateEnd: this.pubStkStock.PDateEnd,
          Model: this.pubStkStock.Model,
          Brand: this.pubStkStock.Brand,
          DeliveryDate: this.pubStkStock.DeliveryDate,
          SType: this.pubStkStock.SType,
          SStatus: this.pubStkStock.SStatus
        }
      );
      return data;
    },
    onShowFiedsSearch() {
      this.isShowFiedsSearch = !this.isShowFiedsSearch;
    },
    openAccountSet() {
      if (!this.hasService) {
        return this.$message({
          message: "你尚未开通库存推广服务，请联系正能量客服人员",
          type: "error"
        });
      }
      this.pubAccountSetVisible = true; // 显示页面
    },
    batchDispaly() {
      this.dialogtitle = "批量展示";
      this.setStockStatus(1);
    },
    batchCancelClick() {
      this.dialogtitle = "批量取消";
      this.setStockStatus(0);
    },
    batchDeleteClick() {
      this.dialogtitle = "批量删除";
      this.batchDelete();
    },

    setStockStatus(newSStatus) {
      if (!this.hasService) {
        return this.$message({
          message: "你尚未开通库存推广服务，请联系正能量客服人员",
          type: "error"
        });
      }
      var _vue = this;
      var psids = [];
      var rows = _vue.$refs.flexGrid.getSelectedRows();
      if (!_.isUndefined(rows[0])) {
        this.$confirm(
          "即将" +
            (newSStatus === 1 ? "展示【" : "取消【") +
            rows.length +
            "】条推广库存, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        ).then(() => {
          psids = rows.map(function(v) {
            return v.PSID;
          });
          this.pubStkStock.PSIDs = psids;
          var jsonData = _.extend(
            {},
            {
              FieldWhereString: JSON.stringify(this.pubStkStock),
              // PSIDs: psids,
              NewSStatus: newSStatus,
              PDateStart: this.pubStkStock.PDateStart,
              PDateEnd: this.pubStkStock.PDateEnd
            },
            this.pubStkStock
          );
          this.$post(CONFIG.setStockStatusUrl, jsonData, (data, logic) => {
            if (logic.code === 200) {
              this.$refs.flexGrid.clearSelection();
              if (data === 0) {
                this.$message({
                  message:
                    newSStatus === 1 ? "没有库存被展示" : "没有库存被取消",
                  type: "error"
                });
              } else {
                this.$message({
                  message:
                    newSStatus === 1
                      ? "【" + data + "】条库存展示成功！"
                      : "【" + data + "】条库存取消成功！",
                  type: "success"
                });
              }
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
          }).then(() => {
            this.onBindData(this.pageIndex);
          });
        });
      } else {
        return this.$message({
          message: "请选择一条数据进行操作",
          type: "error"
        });
      }
    },

    batchDelete() {
      if (!this.hasService) {
        return this.$message({
          message: "你尚未开通库存推广服务，请联系正能量客服人员",
          type: "error"
        });
      }
      var _vue = this;
      var psids = [];
      var rows = this.selectedRows;
      if (!_.isUndefined(rows[0])) {
        this.$confirm("删除确认, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          var checkSStatus = [];
          psids = rows.map(function(v) {
            return v.PSID;
          });
          checkSStatus = rows.map(function(v) {
            return v.SStatus;
          });
          this.pubStkStock.PSIDs = psids;
          if (_.indexOf(checkSStatus, true) >= 0) {
            this.$message({ message: "已展示库存不能直接删除", type: "error" });
            return false;
          }
          var jsonData = _.extend(
            {},
            {
              FieldWhereString:
                Object.keys(this.pubStkStock).length > 0
                  ? JSON.stringify(_.extend(this.pubStkStock, { PSIDs: psids }))
                  : JSON.stringify({ PSIDs: psids }),
              PSIDs: psids
            },
            this.searchModel.data
          );
          this.$post(CONFIG.clearURL, jsonData, (data, logic) => {
            if (logic.code === 200) {
              if (data > 0) {
                _vue.onSearch();
                this.$message({ message: "删除成功", type: "success" });
              } else {
                this.$message({ message: "删除失败", type: "error" });
              }
            } else {
              this.$message({ message: logic.msg, type: "error" });
            }
          }).then(() => {
            this.onBindData(1);
          });
        });
      } else {
        return this.$message({
          message: "请选择一条数据进行操作",
          type: "error"
        });
      }
    },

    onSearch(params) {
      let rows = this.$refs.table.getChangedData();
      if (rows.length > 0) {
        this.$confirm("当前数据已发生修改, 是否继续操作?", "提示", {
          confirmButtonText: "是",
          cancelButtonText: "否",
          type: "warning"
        }).then(() => {
          this.onCleaSelectCount();
          if (this.hasValue(params)) {
            this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
          }

          return this.onBindData(1);
        });
      } else {
        this.onCleaSelectCount();
        if (this.hasValue(params)) {
          this.fieldsFilter = _.extend({}, this.fieldsFilter, params);
        }
        return this.onBindData(1);
      }
    },

    // 批量导入推广库存
    batchImport2() {
      if (!this.hasService) {
        return this.$message({
          message: "你尚未开通库存推广服务，请联系正能量客服人员",
          type: "error"
        });
      }
      this.pubStockImportVisible = true;
    },

    //  0.批量更改展示类型
    //  1. 批量展出
    //  2.批量取消
    //  3.批量删除
    //  4.批量设置同步
    //  5.批量取消同步
    openUpdateExtendType(type) {
      if (!this.hasService) {
        return this.$message({
          message: "你尚未开通库存推广服务，请联系正能量客服人员",
          type: "error"
        });
      }
      let rows = this.selectedRows;
      if (this.hasValue(rows) && rows.length > 0) {
        this.checkedPSID = _.map(rows, m => m.PSID);
        if (rows.length === 1) {
          this.selectedSType = rows[0].SType;
        }
      } else {
        this.checkedPSID = [];
      }
      this.searchCondition = this.getSearchWhere(1);
      if (type === 0) {
        this.title = "更改展示类型";
        this.heights = "320px";
        this.isShowStype = true;
      } else if (type === 1) {
        this.title = "批量展出";
        this.heights = "160px";
        this.isShowStype = false;
      } else if (type === 2) {
        this.title = "批量取消";
        this.heights = "160px";
        this.isShowStype = false;
      } else if (type === 3) {
        this.title = "批量删除";
        this.heights = "160px";
        this.isShowStype = false;
      }
      this.isExtedType = type;
      this.stockExtendVisible = true;
    },

    // 导出库存
    async onExport() {
      this.loading = true;
      this.loading = await this.exportData(
        "SpmPubStkStock",
        this.getSearchWhere(1),
        this.columns,
        "库存推广"
      );
    },
    showExportColumn() {
      this.setExportColumnVisible = true;
      this.addColumnsList = this.columns;
      this.setSaveConfigURL = this.$options.config.saveConfigURL;
    },

    showStypeText(val) {
      let obj = _.find(this.STypeOptions, st => st.value === val.ExtensionType);
      if (obj) {
        return obj.key;
      } else {
        return "";
      }
    },
    onBatchSave() {
      let rows = this.$refs.table.getChangedData();
      if (rows === undefined || rows === null || rows.length <= 0) {
        return this.$message({ message: "数据没有发生变化", type: "warning" });
      } else {
        this.onSaveRows(rows);
      }
    },
    // 勾选选择框事件
    selectChange(rows) {
      this.selectedRows = rows;
    },
    // 清除选中行
    onCleaSelectCount() {
      this.selectedRows = [];
      this.$refs.table.cleanAllCheck();
    },
    // 表格设置的事件
    onHideShowColTrigger(e) {
      this.hideShowColTrigger("PubStkStock", e.col);
    },
    onDragDone(col) {
      this.setTableColWidth("PubStkStock", col);
    },
    onSaveConfig(cols, isDelay) {
      this.columns = cols;
      this.saveErpTableConfig("PubStkStock", cols, isDelay);
    },
    onResetTableConfigClick(e) {
      this.resetTableConfig("PubStkStock");
    }
  }
};
</script>

<style lang="scss">
.stsPurchaseIn-amount {
  color: rgb(255, 0, 0);
}
</style>

