import { render, staticRenderFns } from "./PricingHelp.vue?vue&type=template&id=56f3dfef&"
import script from "./PricingHelp.vue?vue&type=script&lang=js&"
export * from "./PricingHelp.vue?vue&type=script&lang=js&"
import style0 from "./PricingHelp.vue?vue&type=style&index=0&id=56f3dfef&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports